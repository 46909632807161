import {HTMLAttributes} from "react";
import {tx} from "@twind/core";

export type GridProps = HTMLAttributes<HTMLDivElement> & {
  rowCount?: number,
  colCount?: number
}

export const Grid = ({children, className, rowCount, colCount, ...restProps}: GridProps) => {
  return (
    <div
      {...restProps}
      className={tx(
        `grid gap-2 mobileSmall:gap-1 w-full`,
        colCount !== undefined ? `grid-cols-${colCount}`: undefined,
        rowCount !== undefined ? `grid-rows-${rowCount}`: undefined,
        className
      )}
    >
      {children}
    </div>
  )
}

