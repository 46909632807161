import {defineConfig} from "@twind/core";
import appColors from "./colors";
import presetAutoprefix from '@twind/preset-autoprefix'
import presetTailwind from '@twind/preset-tailwind'
import presetTailwindForms from '@twind/preset-tailwind-forms'
import presetTypography from '@twind/preset-typography'

/**
 * The tailwind configuration used by the application
 */
const twindConfig = defineConfig({
  theme: {
    extend: {
      colors: {
        background: {
          50: "#CCCCCC",
          100: "#555555",
          200: "#333333",
          300: "#222222",
        },
        ...appColors
      }
    },
    screens: {
      mobileSmall: {
        max: '480px',
      },
      mobileBig: {
        min: '480px',
        max: '768px'
      },
      mobile: { max: '768px' },
      tablet: { min: '768px', max: '1024px' },
      desktop: { min: '1024px' },
    },
  },
  ignorelist: ["no-spin", /^lucide/],
  presets: [presetAutoprefix(), presetTailwind(), presetTailwindForms(), presetTypography()]
})

export default twindConfig
