import {useTranslation} from "../localization/useTranslation";
import {tw} from "@twind/core";

export const LoadingDisplay = () => {
  const translation = useTranslation()

  return (
    <div className={tw("flex flex-col w-full h-full justify-center items-center p-4")}>
      {translation.loading}...
    </div>
  )
}
