import {Encoding} from "../types/encoding";

/**
 * A function mapping a boundValue to a value by using an Encoding list
 */
export const encodingMapper = <T,>(boundValue: number, encoding: Encoding<T>): T => {
  if(encoding.length < 1) {
    console.error(`getDiscreteColor: The provided discreteColorCoding does not contain sufficient entries`)
  }
  let lastIndex = 0;
  for(let index = 1; index < encoding.length; index++){
    if(boundValue >= encoding[index].bound){
      lastIndex = index
    } else {
      return encoding[lastIndex].value
    }
  }
  return encoding[lastIndex].value
}

/**
 * A function mapping a boundValue to a Grade by using an Encoding list
 */
export const gradeEncodingMapper = encodingMapper<number>
