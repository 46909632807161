import {judgeChairID} from "../types/judge";
import {SpeakerCategory} from "../types/categories";

/** The currently supported languages */
export const languages = ["de", "en"] as const

/**
 * The type for the currently supported languages
 */
export type Languages = typeof languages[number]

/**
 * The default language
 */
export const defaultLanguage: Languages = "de"

/**
 * The definition of what values should be translated
 */
export type Translation = {
  dontHaveARoom: string,
  create: string,
  joinRoomWithCode: string,
  join: string,
  welcomeText: string,
  switchLanguage: string,
  welcome: string,
  whoYou: string,
  addWing: string,
  speechName: string,
  sum: string,
  government: string,
  opposition: string,
  freeSpeeches: string,
  chairName: string,
  wingName: string,
  gradeLabels: string[],
  interaction: string,
  counterSpeech: string,
  questions: string,
  interjections: string,
  strategy: string,
  persuasion: string,
  evaluation: string,
  endResult: string,
  resultSum: string,
  speechResult: string,
  teamResult: string,
  spread: string,
  average: string,
  notConnected: string,
  connecting: string,
  connected: string,
  copy: string,
  roomCode: string,
  close: string,
  speech: (position: number) => string,
  max: string,
  /** The chair has the id judgeChairId */
  judge: (id: number) => string,
  de: string,
  en: string,
  wantToRemove: (objectName: string) => string,
  cannotBeUndone: string,
  remove: string,
  cancel: string,
  save: string,
  edit: string,
  update: string,
  loading: string,
  error: string,
  speakerCategoryLong: (category: SpeakerCategory) => string,
  speakerCategoryShort: (category: SpeakerCategory) => string,
  notEvaluated: string,
  room: string,
  result: string,
  roomSetting: string,
  speechCount: string,
  roomSettingsWarning: string,
  team: string,
  speaker: string,
  notAnswered: string,
  details: string,
  you: string,
  visible: string,
  notVisible: string,
  speakerTab: string,
  points: string,
  sorting: string,
  deductions: string
}

/**
 * The translations by the application
 */
export const translations: Record<Languages, Translation> = {
  de: {
    dontHaveARoom: "Du hast noch keinen Raum?",
    create: "Erstellen",
    joinRoomWithCode: "Nutze einen Raumcode, um deinem Raum beizutreten",
    join: "Beitreten",

    welcomeText: "Willkommen bei Mittelmaß!",
    switchLanguage: "Switch to english",

    welcome: "Willkommen!",
    whoYou: "Wer bist du?",
    addWing: "Wing hinzufügen",

    speechName: "Rede",
    sum: "Summe",

    government: "Regierung",
    opposition: "Opposition",
    freeSpeeches: "Freie",

    chairName: "Chair",
    wingName: "Wing",

    gradeLabels: [
      "6",
      "5-",
      "5",
      "5+",
      "4-",
      "4",
      "4+",
      "3-",
      "3",
      "3+",
      "2-",
      "2",
      "2+",
      "1-",
      "1",
      "1+",
      "Exzellent",
      "Perfekt",
    ],
    interaction: "Interaktion",
    counterSpeech: "Gegenreden",
    questions: "Fragen",
    interjections: "Zwischenrufe",
    strategy: "Strategie",
    persuasion: "Überzeugung",
    evaluation: "Mittelung",
    endResult: "Gesamtergebnis",
    resultSum: "Gesamt",
    speechResult: "Einzelreden",
    teamResult: "Teampunkte",
    spread: "Spread",
    average: "Schnitt",
    notConnected: "Nicht Verbunden",
    connecting: "Verbinde...",
    connected: "Verbunden",
    copy: "Kopieren",
    roomCode: "Raumcode",
    close: "Schließen",
    speech: (position) => `Rede ${position + 1}`,
    max: "max.",
    judge: (position) => position === judgeChairID ? "Chair" : `Wing ${position}`,
    de: "Deutsch",
    en: "Englisch",
    wantToRemove: objectName => `${objectName} entferen?`,
    cannotBeUndone: "Diese Aktion kann nicht rückgängig gemacht werden.",
    remove: "Entfernen",
    cancel: "Abbrechen",
    save: "Speichern",
    edit: "Ändern",
    update: "Ändern",
    loading: "Laden",
    error: "Fehler",
    speakerCategoryLong: (category) => ({
      linguisticPower: "Sprachkraft",
      appearance: "Auftreten",
      contact: "Kontaktfähigkeit",
      expertise: "Sachverstand",
      judgement: "Urteilskraft"
    }[category]),
    speakerCategoryShort: (category) => ({
      linguisticPower: "SP",
      appearance: "AT",
      contact: "KF",
      expertise: "SV",
      judgement: "UK"
    }[category]),
    notEvaluated: "Nicht Bewertet!",
    room: "Raum",
    result: "Tab",
    roomSetting: "Raumeinstellungen",
    speechCount: "Anzahl der Reden",
    roomSettingsWarning: "Änderungen an diesen Einstllungen werden auf alle Judges angewendet und" +
      " sollten nur von einer Person verändert werden (Gefahr inkonsister Zustände/Daten verfall). " +
      "Gelöschte Speaker Daten können nicht wiederhergestellt werden.",
    team: "Team",
    speaker: "Redner",
    notAnswered: "N/A",
    details: "Details",
    you: "Du",
    visible: "Sichtbar",
    notVisible: "Nicht Sichtbar",
    speakerTab: "Redner Tab",
    points: "Punktzahl",
    sorting: "Sortierung",
    deductions: "Abzüge"
  },
  en: {
    dontHaveARoom: "You don't have a room yet?",
    create: "Create",
    joinRoomWithCode: "Use a room-code to joint your room",
    join: "Join",

    welcomeText: "Welcome to Mittelmaß!",
    switchLanguage: "Zu deutsch wechseln",

    welcome: "Welcome!",
    whoYou: "Who are you?",
    addWing: "Add wing",

    speechName: "Speech",
    sum: "Sum",

    government: "Government",
    opposition: "Opposition",
    freeSpeeches: "Free",

    chairName: "Chair",
    wingName: "Wing",

    gradeLabels: [
      "F",
      "E-",
      "E",
      "E+",
      "D-",
      "D",
      "D+",
      "C-",
      "C",
      "C+",
      "B-",
      "B",
      "B+",
      "A-",
      "A",
      "A+",
      "Excellent",
      "Perfect",
    ],
    interaction: "Interaction",
    counterSpeech: "Replies",
    questions: "Questions",
    interjections: "Interjections",
    strategy: "Strategy",
    persuasion: "Persuasion",
    evaluation: "Evaluation",
    endResult: "End result",
    resultSum: "Total",
    speechResult: "Speeches",
    teamResult: "Team points",
    spread: "Spread",
    average: "Average",
    notConnected: "Not Connected",
    connecting: "Connecting...",
    connected: "Connected",
    copy: "Copy",
    roomCode: "Roomcode",
    close: "Close",
    speech: (position) => `Speech ${position + 1}`,
    max: "max.",
    judge: (position) => position === judgeChairID ? "Chair" : `Wing ${position}`,
    de: "German",
    en: "English",
    wantToRemove: objectName => `Remove ${objectName}?`,
    cannotBeUndone: "This action cannot be undone",
    remove: "Remove",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    update: "Update",
    loading: "Loading",
    error: "Error",
    speakerCategoryLong: (category) => ({
      linguisticPower: "Speaking",
      appearance: "Appearance",
      contact: "Contact",
      expertise: "Expertise",
      judgement: "Judgement"
    }[category]),
    speakerCategoryShort: (category) => ({
      linguisticPower: "SP",
      appearance: "AP",
      contact: "CT",
      expertise: "EX",
      judgement: "JU"
    }[category]),
    notEvaluated: "Not Evaluated!",
    room: "Room",
    result: "Tab",
    roomSetting: "Room Settings",
    speechCount: "Number of speeches",
    roomSettingsWarning: "Changes to theses settings are applied for all judges and should only be made" +
      " by one person (danger of inconsistent states/data loss). " +
      "Deleted speaker data cannot be restored.",
    team: "Team",
    speaker: "Redner",
    notAnswered: "N/A",
    details: "Details",
    you: "You",
    visible: "Visible",
    notVisible: "Not Visible",
    speakerTab: "Speaker Tab",
    points: "Points",
    sorting: "Sorting",
    deductions: "Deductions"
  },
}
