/** The list of all speaker categories */
export const speakerCategories = ["linguisticPower", "appearance", "contact", "expertise", "judgement"] as const

/** A Type for the speaker category */
export type SpeakerCategory = typeof speakerCategories[number]

export const interactionCategories = ["counterSpeech", "questions", "interjections"] as const

/** A Type for the team categories connected to the team interactions */
export type InteractionCategory = typeof interactionCategories[number]

/** The list of all team categories connected to the team itself */
export const teamCategoriesWithoutStrategy = [...interactionCategories, "persuasion"] as const

/** A Type for the team categories connected to the team itself */
export type TeamCategoryWithoutStrategy = typeof teamCategoriesWithoutStrategy[number]

/** The list of all team categories */
export const teamCategories = ["strategy", ...teamCategoriesWithoutStrategy] as const

/** A Type for the team categories */
export type TeamCategory = typeof teamCategories[number]
