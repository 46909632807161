import {ReactElement, useContext} from "react";
import {View} from "../types/views";
import {StartView} from "./StartView";
import {TeamViews} from "./TeamViews";
import {tw} from "@twind/core";
import RoomSetupView from "./RoomSetupView";
import EvaluationView from "./EvaluationView";
import {ResultsView} from "./ResultsView";
import {AppContext} from "../appContext";
import TopNavigation from "../components/TopNavigation";
import {BottomNavigationBar} from "../components/BottomBar";

/**
 * The ViewHandler is a basic Routing component which uses the AppContext to find the correct view to show to the user.
 * @constructor
 */
export const ViewHandler = () => {
  const {activeView} = useContext(AppContext)

  const viewMapping: Record<View, ReactElement> = {
    start: <StartView/>,
    roomSetup: <RoomSetupView/>,
    government: <TeamViews key={"government"} affiliation={"government"}/>,
    opposition: <TeamViews key={"opposition"} affiliation={"opposition"}/>,
    freeSpeeches: <TeamViews key={"freeSpeeches"} affiliation={"freeSpeeches"}/>,
    evaluation: <EvaluationView/>,
    results: <ResultsView/>
  }

  return (
    <>
      <TopNavigation/>
      <div className={tw("flex flex-1 flex-col items-center w-full h-full overflow-auto")}>
        {viewMapping[activeView]}
      </div>
      {activeView !== "start" && (
        <BottomNavigationBar/>
      )}
    </>
  )
}
