import {Languages, Translation, translations} from "./localization";
import {useContext} from "react";
import {AppContext} from "../appContext";

/**
 * A Hook to easily access the translations in the user-selected language
 * @param overWriteLanguage Can be used to get the translation of a specific language
 * @param translation The underlying translations used. Defaults to the translations
 */
export const useTranslation = (
  overWriteLanguage?: Languages,
  translation: Record<Languages, Translation> = translations
) => {
  const {language} = useContext(AppContext)
  return translation[overWriteLanguage ?? language]
}
