import {HTMLAttributes, useContext} from "react";
import CategoryCounter, {CategoryCounterProps} from "../CategoryCounter";
import {TeamAdjudication} from "../../types/states";
import {SpeakerAffiliation} from "../../types/teamTypes";
import {AppContext} from "../../appContext";
import {useTranslation} from "../../localization/useTranslation";
import {useUpdateSpeakerAdjudication} from "../../api/api";
import {tx} from "@twind/core";
import {produce} from "immer";

type TeamStrategyDisplayProps = HTMLAttributes<HTMLDivElement>
  & Pick<CategoryCounterProps, "hideValue" | "isEditable" | "isShowingMaximum" | "isShowingGrades" | "largeIncrement">
  & {
  adjudication: TeamAdjudication,
  affiliation: SpeakerAffiliation,
  onSpeechClick?: (position: number) => void
}

export const TeamStrategyDisplay = ({
                                      affiliation,
                                      adjudication,
                                      className,
                                      isEditable,
                                      hideValue,
                                      isShowingGrades,
                                      isShowingMaximum,
                                      largeIncrement = 5,
                                      onSpeechClick,
                                      ...props
                                    }: TeamStrategyDisplayProps) => {
  const {config} = useContext(AppContext)
  const translation = useTranslation()
  const update = useUpdateSpeakerAdjudication()

  return (
    <div
      {...props}
      className={tx(`grid grid-cols-${adjudication.speakers.length} gap-x-2 mobileSmall:gap-x-1 w-full`, className)}
    >
      {adjudication.speakers.map(speaker => (
        <CategoryCounter
          key={speaker.position}
          {...config.teamCategory.strategy}
          title={translation.speech(speaker.position)}
          value={speaker.strategy}
          hideValue={hideValue}
          isEditable={isEditable}
          isShowingMaximum={isShowingMaximum}
          isShowingGrades={isShowingGrades}
          largeIncrement={largeIncrement}
          onChange={value => {
            update(produce(speaker, (draft) => {
              draft.strategy = value
            }), affiliation)
          }}
          onClick={onSpeechClick ? () => onSpeechClick(speaker.position) : undefined}
          className={tx("flex flex-1")}
        />
      ))}
    </div>
  )
}
