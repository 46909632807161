import {useState} from "react";
import {useTranslation} from "../localization/useTranslation";
import {ViewBase} from "./ViewBase";
import {tw} from "@twind/core";
import {Button} from "../components/basic/Button";
import {useCreateNewRoom} from "../api/api";
import {Card} from "../components/basic/Card";
import {LogIn, Plus} from "lucide-react";
import {Input} from "../components/basic/Input";
import {useSession} from "../session/useSession";

/**
 * The StartView is the first view a user sees when not joining via a link. It allows the user to
 * create or join a room.
 * @constructor
 */
export const StartView = () => {
  const translation = useTranslation()
  const createNewRoom = useCreateNewRoom()
  const {connect} = useSession()
  const [roomCode, setRoomCode] = useState("")

  const isValidRoomCode = (roomCode: string) => {
    return roomCode.length === 4
  }

  return (
    <ViewBase titleText={translation.welcomeText} className={tw("flex flex-col items-center gap-y-8")}>
      <Card title={<h2 className={tw("font-semibold text-lg")}>{translation.dontHaveARoom}</h2>}
            className={tw("max-w-[400px]")}>
        <Button onClick={createNewRoom} coloring={{color: "positive"}} isRow={true} className={tw("mt-2")}>
          <Plus size={18}/>
          {translation.create}
        </Button>
      </Card>

      <Card title={<h2 className={tw("font-semibold text-lg")}>{translation.joinRoomWithCode}</h2>}
            className={tw("max-w-[400px]")}>
        <div className={tw("flex flex-row gap-x-2 justify-between w-full mt-2")}>
          <Input
            id={"roomCode"}
            type={"text"}
            value={roomCode}
            onChange={value => setRoomCode(value)}
            className={tw("rounded-md text-black-base")}
            placeholder={translation.roomCode}
          />
          <Button
            disabled={!isValidRoomCode(roomCode)}
            onClick={() => connect(roomCode)}
            coloring={{color: "positive"}}
            isRow={true}
          >
            <LogIn size={18}/>
            {translation.join}
          </Button>
        </div>
      </Card>
    </ViewBase>
  )
}
