import "../../style.css";
import {AppContext} from "../../appContext";
import {useContext, useState} from "react";
import {SpeakerAffiliation} from "../../types/teamTypes";
import {useTranslation} from "../../localization/useTranslation";
import {tw, tx} from "@twind/core";
import {Card} from "../basic/Card";
import CategoryCounter from "../CategoryCounter";
import {encodingMapper} from "../../util/encodingMapper";
import {Coloring} from "../../configs/colors";
import {ScoreAdjudicationHeader} from "../ScoreAdjudicationHeader";
import {useMyAdjudication} from "../../util/hooks/useMyAdjudication";
import {useResult} from "../../util/hooks/useResult";
import {produce} from "immer";
import {useUpdateSpeakerAdjudication, useUpdateSpeakerDeductions} from "../../api/api";
import {judgeChairID} from "../../types/judge";
import {findSpeakerAdjudication, findSpeakerDeduction} from "../../util/debateStateHelper";
import {SpeakerCategoriesDisplay} from "../category_displays/SpeakersCategoriesDisplay";
import {useSession} from "../../session/useSession";
import {LoadingDisplay} from "../LoadingDisplay";
import {EvaluationExpansionHeader} from "./EvaluationExpansionHeader";
import {Deduction} from "../../types/deduction";
import {Tag} from "../basic/Tag";
import {CheckBox} from "../basic/CheckBox";
import {Grid} from "../basic/Grid";
import {speakerCategories} from "../../types/categories";

type DeductionBannerProps = {
  appliedDeductions: Deduction[],
  onChange: (newDeductions: Deduction[]) => void,
  className?: string
}

const DeductionBanner = ({appliedDeductions, onChange, className}: DeductionBannerProps) => {
  const translation = useTranslation()
  const {config} = useContext(AppContext)

  const isUsed = (name: string): boolean => {
    return !!appliedDeductions.find(value => value.name === name)
  }

  const toggleDeduction = (deduction: Deduction) => {
    const isInDeductions = appliedDeductions.find(value => value.name === deduction.name)
    if (isInDeductions) {
      onChange(appliedDeductions.filter(value => value.name !== deduction.name))
    } else {
      onChange([...appliedDeductions, deduction])
    }
  }

  return (
    <Tag
      coloring={{color: "white", hover: false}}
      isRow={true}
      className={tx("!justify-between gap-x-6", className)}
      size={"normal"}
    >
      <span className={tw("font-semibold")}>{translation.deductions}</span>
      <div className={tw("flex flex-row gap-x-2")}>
        {config.deductions.map(({name, penalty}) => (
          <Tag
            key={name}
            coloring={{color: isUsed(name) ? "bad" : "good"}}
            isRow={true}
            onClick={() => toggleDeduction({name, penalty})}
            className={tw("cursor-pointer")}
          >
            <CheckBox onClick={() => toggleDeduction({name, penalty})} value={isUsed(name)}/>
            <span className={tw("font-semibold")}>{penalty}</span>
          </Tag>
        ))}
      </div>
    </Tag>
  )
}


type SpeakerEvaluationProps = {
  affiliation: SpeakerAffiliation,
  position: number
}

/**
 * The component for an individual speaker. Creates five counters for team categories.
 */
const SpeakerEvaluation = ({
                             affiliation,
                             position
                           }: SpeakerEvaluationProps) => {
  const {config, debate} = useContext(AppContext)
  const {judgeId} = useSession()
  const translation = useTranslation()
  const adjudication = useMyAdjudication()
  const result = useResult()
  const [expansionJudgeId, setExpansionJudgeId] = useState(0)
  const updateSpeaker = useUpdateSpeakerAdjudication()
  const updateSpeakerDeductions = useUpdateSpeakerDeductions()

  if (!debate || !adjudication || !result) {
    return <LoadingDisplay/>
  }

  const deductions: Deduction[] = findSpeakerDeduction(debate, affiliation, position)?.deductions ?? []
  const speakerResult = result[affiliation].speakers[position]
  const hasSpeakerResult = speakerResult.scores.some(value => value !== undefined)
  const average = speakerResult.finalScore

  const speakerAdjudication = findSpeakerAdjudication(debate, expansionJudgeId === -1 ? (judgeId ?? judgeChairID) : expansionJudgeId, affiliation, position)!
  const speakerName = `${translation.speech(speakerAdjudication.position)}` + (!!speakerAdjudication.name ? ` (${speakerAdjudication.name})` : "")

  return (
    <Card
      title={(
        <ScoreAdjudicationHeader
          title={`${translation[affiliation]} ${speakerName}`}
          titleColoring={{color: affiliation}}
          average={hasSpeakerResult ? average - speakerResult.deductionSum : undefined}
          deduction={speakerResult.deductionSum !== 0 ? speakerResult.deductionSum : undefined}
          warning={!hasSpeakerResult}
          sumColoring={encodingMapper<Coloring>(average, config.totalSpeakerScoreColorEncoding)}
        />
      )}
      expansionConfig={{isExpanded: false, enabled: true, showIcon: true}}
    >
      {({isExpanded, updateConfig}) => (
        <>
          <DeductionBanner
            appliedDeductions={deductions}
            onChange={(deductions) => updateSpeakerDeductions(deductions, affiliation, position)}
            className={tw("mt-4")}
          />
          <div
            className={tx(
              `grid grid-cols-${speakerResult.scores.length + 1} gap-x-2 mobileSmall:gap-x-1 mt-4`,
              {"mb-2": isExpanded}
            )}
          >
            {speakerResult.scores.map((score, judgeIndex) => (
              <CategoryCounter
                key={judgeIndex}
                title={judgeIndex === judgeId ? ` (${translation.you})` : translation.judge(judgeIndex)}
                colorEncoding={config.totalSpeakerScoreColorEncoding}
                value={score ?? 0}
                hideValue={score === undefined}
                isShowingWarning={score === undefined}
                onClick={() => {
                  updateConfig(prevState => produce(prevState, draft => {
                    draft.isExpanded = true
                  }))
                  setExpansionJudgeId(judgeIndex)
                }}
                className={tx("flex flex-1 cursor-pointer")}
              />
            ))}
            <CategoryCounter
              colorEncoding={config.spreadColorEncoding}
              largeIncrement={0} key={"spread"}
              title={translation.spread}
              value={speakerResult.spread}
              className={tx("flex flex-1 border-transparent")}
              onClick={() => {
                updateConfig(prevState => produce(prevState, draft => {
                  draft.isExpanded = true
                }))
                setExpansionJudgeId(-1)
              }}
            />
          </div>
          {isExpanded && (
            <div
              className={tx("flex flex-col items-center w-full pt-2 border-t-1", {"gap-y-2 !pt-4": expansionJudgeId === -1})}>
              {expansionJudgeId === -1 ? (
                <>
                  <Grid
                    key={"judges"}
                    colCount={debate.adjudications.length + 1}
                  >
                    {debate.adjudications.map((adjudication, judgeIndex) => (
                      <Tag
                        coloring={{color: "white", hover: false}}
                        isRow={true}
                      >
                        <span className={tw("font-medium")}>{translation.judge(judgeIndex)}</span>
                      </Tag>
                    ))}
                    <Tag coloring={{color: "white", hover: false}} isRow={true}>
                      <span className={tw("font-medium")}>{translation.spread}</span>
                    </Tag>
                  </Grid>
                  {speakerCategories.map((category) => (
                    <Grid
                      key={category}
                      colCount={debate.adjudications.length + 1}
                    >
                      {debate.adjudications.map((adjudication, judgeIndex) => {
                        const speakerAdjudication = findSpeakerAdjudication(debate, judgeIndex, affiliation, position)
                        if (!speakerAdjudication) {
                          return undefined
                        }
                        return (
                          <CategoryCounter
                            key={`${judgeIndex}-${category}`}
                            colorEncoding={config.speakerCategory[category].colorEncoding}
                            title={translation.speakerCategoryShort(category)}
                            value={speakerAdjudication.categoryScore[category]}
                            hideValue={!speakerAdjudication.shouldEvaluateCategories}
                          />
                        )
                      })}
                      <CategoryCounter
                        key={`${category}-spread`}
                        colorEncoding={config.spreadColorEncoding}
                        title={translation.spread}
                        value={speakerResult.subcategories[category].spread}
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <>
                  <EvaluationExpansionHeader
                    expansionJudgeId={expansionJudgeId}
                    onChangeIsEvaluated={() => updateSpeaker(produce(speakerAdjudication, (draft) => {
                      draft.shouldEvaluateCategories = !draft.shouldEvaluateCategories
                    }), affiliation, expansionJudgeId)}
                    isEvaluated={speakerAdjudication.shouldEvaluateCategories}
                  />
                  <SpeakerCategoriesDisplay
                    speakerAdjudication={speakerAdjudication}
                    hideValue={!speakerAdjudication.shouldEvaluateCategories && expansionJudgeId !== judgeId}
                    className={tx("mt-4")}
                    isEditable={expansionJudgeId === judgeId}
                    isShowingMaximum={expansionJudgeId === judgeId}
                    isShowingGrades={expansionJudgeId === judgeId}
                    affiliation={affiliation}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </Card>
  )
}
export default SpeakerEvaluation;
