import {speakerAffiliations} from "../types/teamTypes";

export type SimpleColor = {
  base: string,
  text: string,
  hover: string,
}

export const scoreColorCodings = ["very-bad", "bad", "ok", "good", "very-good", "nat-top", "int-top"] as const

export const spreadColorCodings = ["spread-very-bad", "spread-bad", "spread-okay", "spread-good"] as const

export const simpleColorTypes = ["primary", "negative", "positive", "warning", "white", "black", "disabled", ...speakerAffiliations, ...scoreColorCodings, ...spreadColorCodings] as const

export type SimpleColorType = typeof simpleColorTypes[number]

export type ColoringStyle = "background" | "backgroundFull" | "text" | "text-border"

export type Coloring = {
  color: SimpleColorType,
  style?: ColoringStyle,
  hover?: boolean
}

export const appColors: Record<SimpleColorType, SimpleColor> = {
  primary: {
    base: "#0078FF",
    text: "#FFFFFF",
    hover: "#004da3"
  },
  negative: {
    base: "#b30404",
    text: "#FFFFFF",
    hover: "#850000"
  },
  positive: {
    base: "#00ad09",
    text: "#FFFFFF",
    hover: "#008207"
  },
  warning: {
    base: "#FFC02B",
    text: "#000000",
    hover: "#CC9921",
  },
  white: {
    base: "#FFFFFF",
    text: "#000000",
    hover: "#BBBBBB"
  },
  black: {
    base: "#000000",
    text: "#FFFFFF",
    hover: "#333333"
  },
  disabled: {
    base: "#9ca3af",
    text: "#000000",
    hover: "#9ca3af"
  },
  government: {
    base: "#7cafe1",
    text: "#000000",
    hover: "#5f86ad"
  },
  opposition: {
    base: "#a177ce",
    text: "#000000",
    hover: "#78599a"
  },
  freeSpeeches: {
    base: "#f8d48a",
    text: "#000000",
    hover: "#c5a96f"
  },
  "very-bad": {
    base: "#D34545",
    text: "#FFFFFF",
    hover: "#A83838"
  },
  "bad": {
    base: "#FF7878",
    text: "#000000",
    hover: "#D06262"
  },
  ok: {
    base: "#a2a2a2",
    text: "#000000",
    hover: "#838282"
  },
  good: {
    base: "#51C456",
    text: "#000000",
    hover: "#3B913F"
  },
  "very-good": {
    base: "#2A8A47",
    text: "#FFFFFF",
    hover: "#206936"
  },
  "nat-top": {
    base: "#e8d252",
    text: "#000000",
    hover: "#bbae47"
  },
  "int-top": {
    base: "#e3c51b",
    text: "#000000",
    hover: "#AA8F02"
  },
  "spread-very-bad": {
    base: "#007D7D",
    text: "#FFFFFF",
    hover: "#006969"
  },
  "spread-bad": {
    base: "#009191",
    text: "#000000",
    hover: "#007d7d"
  },
  "spread-okay": {
    base: "#00b4b4",
    text: "#000000",
    hover: "#00a0a0"
  },
  "spread-good": {
    base: "#00f0f0",
    text: "#000000",
    hover: "#00d2d2"
  }
}

export default appColors
