import {HTMLAttributes} from "react";
import {tw, tx} from "@twind/core";

type ViewBaseProps = HTMLAttributes<HTMLDivElement> & {
  titleText?: string,
  outerClassName?: string,
}

/**
 * The basic component for all views it adds a maximum width for content
 * which is aligned in the center and add a default padding.
 *
 * Normal styling should be done through className and the removal/change of the padding through outerClassName
 */
export const ViewBase = ({children, titleText, className, outerClassName}: ViewBaseProps) => {
  return (
    <div className={tx("flex flex-col items-center p-6 mobileSmall:p-3 w-full", outerClassName)}>
      <div className={tx("flex flex-col max-w-[1000px] w-full", className)}>
        {!!titleText && (<h2 className={tw("font-bold text-2xl")}>{titleText}</h2>)}
        {children}
      </div>
    </div>
  )
}
