import {Coloring, ColoringStyle} from "../configs/colors";

export const getColoring = (coloring?: Coloring): string => {
  if(!coloring){
    return ""
  }
  const colorType = coloring.color
  const style = coloring.style ?? "background"
  const allowsHover = coloring.hover ?? true

  const colorStyleMapping: Record<ColoringStyle, string> = {
    background: `bg-${colorType}-base text-${colorType}-text`,
    backgroundFull: `bg-${colorType}-base/30 text-${colorType}-base`,
    text: `text-${colorType}-base`,
    "text-border": `text-${colorType}-base border border-2 border-${colorType}-base`
  }
  const color = colorStyleMapping[style]
  const hoverMapping : Record<ColoringStyle, string> = {
    background: ` hover:bg-${colorType}-hover`,
    backgroundFull: ``,
    text: ` hover:text-${colorType}-hover`,
    "text-border": ` hover:text-${colorType}-hover hover:border-${colorType}-hover`
  }
  const hover = allowsHover ? hoverMapping[style] : ""
  return color + hover
}
