import {HTMLAttributes, useContext, useState} from "react";
import {tw, tx} from "@twind/core";
import {Button} from "./basic/Button";
import {useTranslation} from "../localization/useTranslation";
import {Pencil, Plus, Trash2} from "lucide-react";
import {useUpdateDebate} from "../api/api";
import {produce} from "immer";
import {initializeDebateAdjudication} from "../util/initializeDebate";
import {judgeChairID} from "../types/judge";
import {Modal} from "./modal/Modal";
import {useSession} from "../session/useSession";
import {LoadingDisplay} from "./LoadingDisplay";
import {AppContext} from "../appContext";

type OnJudgeSelectHandler = (judgeId: number, defaultUpdater: (judgeId: number) => void) => void

type JudgeManagementProps = Omit<HTMLAttributes<HTMLDivElement>, "children"> & {
  /**
   * Default behavior is to switch screens
   */
  onJudgeSelect?: OnJudgeSelectHandler,
  maxWidth?: string
}

export const JudgeManagement = ({className, onJudgeSelect, maxWidth = "500px", ...restProps}: JudgeManagementProps) => {
  const {updateSession} = useSession()
  const {debate, config} = useContext(AppContext)
  const {judgeId} = useSession()
  const updateDebate = useUpdateDebate()
  const translation = useTranslation()
  // id of the judge to be deleted
  const [isShowingDeleteConfirm, setIsShowingDeleteConfirm] = useState<number>()
  // id of the judge to be edited
  const [isShowingEdit, setIsShowingEdit] = useState<number>()
  const [judgeName, setJudgeName] = useState<string>("")

  if (!debate?.adjudications) {
    return <LoadingDisplay/>
  }

  const judges = debate.adjudications.map(value => value.judge)


  const defaultOnJudgeSelect = (judgeId: number) => updateSession(prevState => produce(prevState, draft => {
    draft.judgeId = judgeId
  }))
  const usedOnJudgeSelect: OnJudgeSelectHandler = onJudgeSelect ?? defaultOnJudgeSelect

  return (
    <div
      {...restProps}
      className={tx(`flex flex-col gap-y-6 max-w-[${maxWidth}] items-center w-full overflow-hidden`, className)}
    >
      <Modal
        id={"deleteConfirmDialog"}
        isOpen={isShowingDeleteConfirm !== undefined}
        onBackgroundClick={() => setIsShowingDeleteConfirm(undefined)}
        onCloseClick={() => setIsShowingDeleteConfirm(undefined)}
        title={(
          <h2 className={tw("text-lg font-semibold")}>
            {isShowingDeleteConfirm !== undefined ? translation.wantToRemove(translation.judge(isShowingDeleteConfirm)) : "translationError"}
          </h2>
        )}
      >
        <div className={tw("flex flex-col gap-y-6 w-full")}>
          <span>{translation.cannotBeUndone}</span>
          <div className={tw("flex flex-row gap-x-2 justify-end")}>
            <Button
              coloring={{color: "negative"}}
              onClick={() => {
                updateDebate(produce(debate, (draft) => {
                  draft.adjudications = draft.adjudications.filter(value => value.judge.id !== isShowingDeleteConfirm)
                }))
                setIsShowingDeleteConfirm(undefined)
              }}
            >
              {translation.remove}
            </Button>
            <Button coloring={{color: "white"}} onClick={() => setIsShowingDeleteConfirm(undefined)}>
              {translation.cancel}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        id={"judgeNameEdit"}
        isOpen={isShowingEdit !== undefined}
        onBackgroundClick={() => setIsShowingEdit(undefined)}
        onCloseClick={() => setIsShowingEdit(undefined)}
        title={(
          <h2 className={tw("text-lg font-semibold")}>
            {isShowingEdit !== undefined ? `${translation.edit} ${translation.judge(isShowingEdit)}` : "translationError"}
          </h2>
        )}
      >
        <div className={tw("flex flex-col gap-y-6 w-full mt-2")}>
          <div className={tw("flex flex-row gap-x-2 w-full justify-between")}>
            <input
              value={judgeName}
              onChange={event => setJudgeName(event.target.value)}
              className={tw("rounded-md px-2 py-1")}
            />
            <Button
              coloring={{color: "negative", style: "text"}}
              onClick={() => setJudgeName("")}
              className={tw("!py-0 px-2")}
              disabled={!judgeName}
            >
              <Trash2 size={18}/>
            </Button>
          </div>
          <div className={tw("flex flex-row gap-x-2 justify-end")}>
            <Button coloring={{color: "white"}} onClick={() => setIsShowingEdit(undefined)}>
              {translation.cancel}
            </Button>
            <Button
              coloring={{color: "primary"}}
              onClick={() => {
                updateDebate(produce(debate, (draft) => {
                  draft.adjudications.find(value => value.judge.id === isShowingEdit)!.judge.name = judgeName
                }))
                setIsShowingEdit(undefined)
              }}
            >
              {translation.update}
            </Button>
          </div>
        </div>
      </Modal>
      <div className={tw("flex flex-col gap-y-4 w-full items-center overflow-auto")}>
        {judges.map(judge => (
          <div key={judge.id} className={"flex flex-row gap-x-2 w-full"}>
            <Button
              coloring={{color: judge.id === judgeId ? "positive" : "white"}}
              className={tw("w-full")}
              onClick={() => usedOnJudgeSelect(judge.id, defaultOnJudgeSelect)}
            >
              {judge.name ? `${translation.judge(judge.id)} (${judge.name})` : `${translation.judge(judge.id)}`}
            </Button>
            {judgeId === judgeChairID && (
              <>
                <Button
                  coloring={{color: "black", style: "text"}}
                  onClick={() => {
                    setIsShowingEdit(judge.id)
                    setJudgeName(judge.name ?? "")
                  }}
                  className={tw("!py-0 !px-2")}
                >
                  <Pencil size={18}/>
                </Button>
                <Button
                  coloring={{color: "negative", style: "text"}}
                  className={tw("!py-0 !px-2")}
                  // Only the last judge can be deleted
                  disabled={judge.id !== judges.length - 1 || judge.id === judgeChairID}
                  onClick={() => setIsShowingDeleteConfirm(judge.id)}
                >
                  <Trash2 size={18}/>
                </Button>
              </>
            )}
          </div>
        ))}
      </div>
      <Button
        key={"addWing"}
        coloring={{color: "primary"}}
        isRow={true}
        className={tw("w-full")}
        onClick={() => {
          updateDebate(produce(debate, (draft) => {
            draft.adjudications.push(initializeDebateAdjudication(config, {id: judges.length}))
          }))
        }}
      >
        <Plus size={18}/>
        {translation.addWing}
      </Button>
    </div>
  )
}
