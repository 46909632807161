import {Team} from "../../types/teamTypes";
import {useContext} from "react";
import {AppContext} from "../../appContext";
import {useTranslation} from "../../localization/useTranslation";
import {calculateStrategyScore} from "../../util/scores";
import {Card} from "../basic/Card";
import {tw} from "@twind/core";
import {produce} from "immer";
import {useUpdateTeamAdjudication} from "../../api/api";
import {useMyAdjudication} from "../../util/hooks/useMyAdjudication";
import {ScoreAdjudicationHeader} from "../ScoreAdjudicationHeader";
import {Coloring} from "../../configs/colors";
import {encodingMapper} from "../../util/encodingMapper";
import {TeamStrategyDisplay} from "../category_displays/TeamStrategyDisplay";
import {LoadingDisplay} from "../LoadingDisplay";

type TeamStrategyAdjudicationProps = {
  team: Team,
}

export const TeamStrategyAdjudication = ({team}: TeamStrategyAdjudicationProps) => {
  const {config} = useContext(AppContext)
  const translation = useTranslation()
  const adjudication = useMyAdjudication()
  const updateTeamAdjudication = useUpdateTeamAdjudication()

  const teamAdjudication = !!adjudication ? adjudication[team] : undefined

  if (!adjudication || !teamAdjudication) {
    return <LoadingDisplay/>
  }

  const totalScore = calculateStrategyScore(teamAdjudication)
  const colorEncoding = config.teamCategory.strategy.colorEncoding.map(element =>
    ({...element, bound: element.bound * teamAdjudication.speakers.length}))

  return (
    <Card
      title={(
        <ScoreAdjudicationHeader
          title={`${translation[team]} ${translation.strategy}`}
          titleColoring={{color: team}}
          selection={{
            selected: teamAdjudication.shouldEvaluateStrategy,
            onSelect: () => {
              const newTeamAdjudication = produce(adjudication[team], (draft) => {
                draft.shouldEvaluateStrategy = !draft.shouldEvaluateStrategy
              })
              updateTeamAdjudication(newTeamAdjudication, team)
            }
          }}
          average={teamAdjudication.shouldEvaluateStrategy ? totalScore : undefined}
          sumColoring={encodingMapper<Coloring>(totalScore, colorEncoding)}
          warning={!teamAdjudication.shouldEvaluateStrategy}
        />
      )}
      expansionConfig={{isExpanded: false, enabled: true, showIcon: true}}
    >
      {({isExpanded, updateConfig}) => (
        <TeamStrategyDisplay
          adjudication={teamAdjudication}
          affiliation={team}
          className={tw("mt-4")}
          isEditable={isExpanded}
          isShowingMaximum={isExpanded}
          isShowingGrades={isExpanded}
          onSpeechClick={!isExpanded ? () => updateConfig(prevState =>
            produce(prevState, draft => {
              draft.isExpanded = true
            })) : undefined}
        />
      )}
    </Card>
  )
}
