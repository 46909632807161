import {Team} from "../../types/teamTypes";
import {useContext} from "react";
import {AppContext} from "../../appContext";
import {useTranslation} from "../../localization/useTranslation";
import {calculateTeamPersuasionScore} from "../../util/scores";
import {Card} from "../basic/Card";
import {tw, tx} from "@twind/core";
import {produce} from "immer";
import CategoryCounter from "../CategoryCounter";
import {useUpdateTeamAdjudication} from "../../api/api";
import {useMyAdjudication} from "../../util/hooks/useMyAdjudication";
import {ScoreAdjudicationHeader} from "../ScoreAdjudicationHeader";
import {Coloring} from "../../configs/colors";
import {encodingMapper} from "../../util/encodingMapper";
import {Grid} from "../basic/Grid";
import {LoadingDisplay} from "../LoadingDisplay";

type TeamPersuasionAdjudicationProps = {
  team: Team,
}

export const TeamPersuasionAdjudication = ({team}: TeamPersuasionAdjudicationProps) => {
  const {config} = useContext(AppContext)
  const translation = useTranslation()
  const adjudication = useMyAdjudication()
  const updateTeamAdjudication = useUpdateTeamAdjudication()

  const teamAdjudication = !!adjudication ? adjudication[team] : undefined

  if (!adjudication || !teamAdjudication) {
    return <LoadingDisplay/>
  }

  const totalScore = calculateTeamPersuasionScore(teamAdjudication)

  return (
    <Card
      title={(
        <ScoreAdjudicationHeader
          title={`${translation[team]} ${translation.persuasion}`}
          titleColoring={{color: team}}
          selection={{
            selected: teamAdjudication.shouldEvaluatePersuasiveness,
            onSelect: () => {
              const newTeamAdjudication = produce(adjudication[team], (draft) => {
                draft.shouldEvaluatePersuasiveness = !draft.shouldEvaluatePersuasiveness
              })
              updateTeamAdjudication(newTeamAdjudication, team)
            }
          }}
          average={teamAdjudication.shouldEvaluatePersuasiveness ? totalScore : undefined}
          sumColoring={encodingMapper<Coloring>(totalScore, config.teamCategory.persuasion.colorEncoding)}
          warning={!teamAdjudication.shouldEvaluatePersuasiveness}
        />
      )}
      expansionConfig={{isExpanded: false, enabled: true, showIcon: true}}
    >
      {({isExpanded, updateConfig}) => (
        <Grid colCount={1} className={tw("mt-4")}>
          <CategoryCounter
            key={"persuasiveness"}
            {...config.teamCategory.persuasion}
            largeIncrement={5}
            title={translation.persuasion}
            onChange={value => {
              updateTeamAdjudication(produce(teamAdjudication, draft => {
                draft.teamCategories.persuasion = value
              }), team)
            }}
            value={teamAdjudication.teamCategories.persuasion}
            isEditable={isExpanded}
            isShowingMaximum={isExpanded}
            isShowingGrades={isExpanded}
            onClick={() => updateConfig(prevState => produce(prevState, draft => {
              draft.isExpanded = true
            }))}
            className={tx("flex flex-1")}
          />
        </Grid>
      )}
    </Card>
  )
}
