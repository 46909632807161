import {CategoryConfig} from "../configs/opd";
import {encodingMapper, gradeEncodingMapper} from "../util/encodingMapper";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "../localization/useTranslation";
import {clamp} from "../util/clamp";
import {noop} from "../util/noop";
import {Button} from "./basic/Button";
import {tw, tx} from "@twind/core";
import {getColoring} from "../util/coloringHelper";
import {Coloring} from "../configs/colors";
import {Input} from "./basic/Input";
import {enforceNumber} from "../util/enforceNumber";
import {AppContext} from "../appContext";
import {AlertTriangle} from "lucide-react";

type UpdateButtonProps = {
  updateValue: number,
  onUpdate: (value: number) => void
}

const UpdateButton = ({updateValue, onUpdate}: UpdateButtonProps) => {
  return (
    <Button
      onClick={() => onUpdate(updateValue)}
      size={"small"}
      className={tw("w-full text-black-base bg-white-base/70 hover:bg-white-base/90 font-medium")}
    >
      {`${updateValue > 0 ? "+" : "-"}${Math.abs(updateValue)}`}
    </Button>
  )
}

export type CategoryCounterProps =
  Pick<CategoryConfig, "colorEncoding">
  & Partial<Omit<CategoryConfig, "colorEncoding">>
  & {
  title: string
  value: number,
  onChange?: (value: number) => void,
  onClick?: () => void,
  isEditable?: boolean,
  isShowingGrades?: boolean,
  isShowingMaximum?: boolean,
  hideValue?: boolean,
  isShowingWarning?: boolean
  className?: string
}

/**
 * A counter, which displays an integer variable and buttons to increment/decrement that variable
 */
export const CategoryCounter = ({
                                  value,
                                  limits,
                                  gradesEncoding = [],
                                  colorEncoding,
                                  largeIncrement = 0,
                                  title,
                                  onChange = noop,
                                  onClick,
                                  isEditable = false,
                                  isShowingGrades = false,
                                  isShowingMaximum = false,
                                  hideValue = false,
                                  isShowingWarning = false,
                                  className,
                                }: CategoryCounterProps) => {
  const {language} = useContext(AppContext)
  const translation = useTranslation(language)
  const [editingValue, setEditingValue] = useState<string>()

  const update = (newValue: number) => {
    if (!limits) {
      console.error("CategoryCounter: cannot update without providing limits")
      return
    }
    let result = clamp(newValue, limits)
    if (result !== value) {
      onChange(result)
    }
  }

  const updateChangeBy = (changeBy: number) => update(value + changeBy)

  useEffect(() => {
    setEditingValue(undefined)
  }, [value])

  const isEditing = editingValue !== undefined
  const interpretedUsedValue = parseInt(editingValue ?? "")
  const isValid = !isNaN(interpretedUsedValue)
  const backGroundDeterminer = isValid ? interpretedUsedValue : value

  return (
    <div
      className={tx(`flex flex-col items-center p-2 mobileSmall:p-1 rounded-lg gap-y-2 mobileSmall:gap-y-1 text-center justify-between`,
        getColoring(!hideValue ? {
          ...encodingMapper<Coloring>(backGroundDeterminer, colorEncoding),
          hover: !isEditable && !!onClick
        } : {color: "disabled"}),
        {"cursor-pointer": !!onClick},
        className,
      )}
      onClick={onClick}
    >
      <div className={tw("flex flex-col items-center w-full")}>
        <p className={tw("font-bold break-words w-full")}>
          {title}
        </p>
        {isShowingMaximum && limits && (
          <p className={tw("text-xs")}>
            {`${translation.max} ${limits[1]}`}
          </p>
        )}
        {isShowingGrades && (
          <p className={tw("my-1 text-xs font-bold")}>
            {`(${translation.gradeLabels[gradeEncodingMapper(backGroundDeterminer, gradesEncoding)]})`}
          </p>
        )}
      </div>
      {isEditable && (
        <>
          <UpdateButton updateValue={largeIncrement} onUpdate={updateChangeBy}/>
          <UpdateButton updateValue={1} onUpdate={updateChangeBy}/>
        </>
      )}
      <div className={tw("flex flex-col w-full items-center gap-y-2")}>
        {hideValue && (
          <div className={tw("flex flex-row gap-x-1 w-full items-center justify-center")}>
            {isShowingWarning && (
              <div className={tx("rounded-md p-[2px]", getColoring({color: "warning", hover: false}))}>
                {/* TODO make the border triangular to */}
                <AlertTriangle size={16}/>
              </div>
            )}
            <p className={tw("font-bold")}>{translation.notAnswered}</p>
          </div>
        )}
        {!hideValue && (isEditable ? (
          <Input
            type={"text"} // Allows for custom and better validation
            className={tw("px-2 py-1 rounded-md text-center no-spin font-bold border-2 border-primary-base")}
            value={isEditing ? editingValue : value.toString()}
            onChange={text => {
              setEditingValue(enforceNumber(text, limits))
            }}
            onEditCompleted={
              text => {
                const parsed = parseInt(text)
                setEditingValue(undefined)
                update(isNaN(parsed) ? value : parsed)
              }
            }
          />
        ) : (
          <p className={tw("font-bold")}>{value}</p>
        ))}
      </div>
      {isEditable && (
        <>
          <UpdateButton updateValue={-1} onUpdate={updateChangeBy}/>
          <UpdateButton updateValue={-largeIncrement} onUpdate={updateChangeBy}/>
        </>
      )}
    </div>
  )
}

export default CategoryCounter;
