import {useTranslation} from "../localization/useTranslation";
import {useResult} from "../util/hooks/useResult";
import {tw, tx} from "@twind/core";
import {Tag} from "./basic/Tag";
import {SpeakerAffiliation} from "../types/teamTypes";
import {createTab} from "../util/results";
import {deduplicate} from "../util/array";
import {SpeakerResult} from "../types/results";
import {useContext} from "react";
import {encodingMapper} from "../util/encodingMapper";
import {LoadingDisplay} from "./LoadingDisplay";
import {AppContext} from "../appContext";

type SpeakerResultDisplayProps = {
  name: string,
  score: number,
  position: number,
  affiliation: SpeakerAffiliation
}

const SpeakerResultDisplay = ({name, score, position, affiliation}: SpeakerResultDisplayProps) => {
  const {config} = useContext(AppContext)

  return (
    <Tag coloring={{color: affiliation, hover: false}} isRow={true} className={tw("w-full !justify-between items-center")}>
      <div className={tw("flex flex-row items-center gap-x-2")}>
        <Tag
          className={tx(
            "font-bold !py-1", {
              "!bg-[#FFD700]": position === 1,
              "!bg-[#C0C0C0]": position === 2,
              "!bg-[#CD7F32]": position === 3
            })}
          coloring={{color: "white", hover: false}}
        >
          {`${position}.`}
        </Tag>
        <span className={tw("font-bold")}>{name}</span>
      </div>
      <Tag coloring={{...encodingMapper(score, config.totalSpeakerScoreColorEncoding), hover: false}}>{score.toFixed(2)}</Tag>
    </Tag>
  )
}

export type SpeakerTabSorting = "team" | "points"

export type SpeakerTabProps = {
  sorting?: SpeakerTabSorting,
  className?: string
}

export const SpeakerTab = ({sorting, className}: SpeakerTabProps) => {
  const translation = useTranslation()
  const result = useResult()

  if (!result) {
    return <LoadingDisplay/>
  }

  const tab = createTab(result)

  const scores = deduplicate(tab.map(value => value.finalScore)).sort((a, b) => a > b ? -1 : 1)
  const scoreSorting = tab.sort((a: SpeakerResult, b: SpeakerResult) => a.finalScore > b.finalScore ? -1 : 1)
  const speakerPositionSorting = (a: SpeakerResult, b: SpeakerResult) => a.position < b.position ? -1 : 1
  const teamSorting = [
    ...tab.filter(value => value.affiliation === "government").sort(speakerPositionSorting),
    ...tab.filter(value => value.affiliation === "opposition").sort(speakerPositionSorting),
    ...tab.filter(value => value.affiliation === "freeSpeeches").sort(speakerPositionSorting)
  ]

  const usedSorting = sorting === "team" ? teamSorting : scoreSorting

  return (
    <div className={tx("flex flex-col gap-y-2 w-full", className)}>
      {usedSorting.map(({affiliation, finalScore, position}) => (
        <SpeakerResultDisplay
          key={affiliation + position}
          name={`${translation[affiliation]} ${translation.speech(position)}`}
          score={finalScore}
          position={scores.findIndex(value => value === finalScore)! + 1}
          affiliation={affiliation}
        />
      ))}
    </div>
  )
}
