import {SpeakerAffiliation} from "../types/teamTypes";
import {LandmarkIcon, LucideProps, MessageCircleWarning, MicVocal} from "lucide-react";

export type AffiliationIconProps = LucideProps & {
  affiliation: SpeakerAffiliation
}

export const AffiliationIcon = ({affiliation, ...props}: AffiliationIconProps) => {
  switch (affiliation){
    case "government": return <LandmarkIcon {...props} />
    case "opposition": return <MessageCircleWarning {...props} />
    case "freeSpeeches": return <MicVocal {...props} />
  }
}
