import {useTranslation} from "../localization/useTranslation";
import {View} from "../types/views";
import {HTMLAttributes, ReactNode, useContext} from "react";
import {tw, tx} from "@twind/core";
import {ArrowRight, Home, Sigma, Table} from "lucide-react";
import {useOnce} from "../util/hooks/useOnce";
import {AffiliationIcon} from "./AffilliationIcon";
import {AppContext} from "../appContext";
import {produce} from "immer";
import {useSession} from "../session/useSession";

type NavItemProps = HTMLAttributes<HTMLDivElement> & {
  icon: ReactNode,
  name: string,
  view: View,
  enabled?: boolean
}

const NavItem = ({icon, name, view, className, enabled = true}: NavItemProps) => {
  const {activeView, update} = useContext(AppContext)
  const warnOnce = useOnce(() => console.warn("Active NavItems should always be enabled"))
  const selected = activeView === view

  if (selected && !enabled) {
    warnOnce()
  }

  return (
    <div
      className={tx(
        "flex flex-col gap-y-1 items-center rounded-full px-4 py-2 text-xs min-w-[62px]",
        {
          "text-white-base bg-white-base/30 font-bold": selected,
          "font-semibold": !selected,
          "text-white-base/80": !selected && enabled,
          "text-white-base/60": !selected && !enabled
        },
        {
          "cursor-pointer hover:bg-white-base/40 hover:text-white-base": enabled,
          "cursor-not-allowed": !enabled
        },
        className)}
      onClick={() => {
        if (enabled) {
          update(prevState => produce(prevState, draft => {draft.activeView = view}))
        }
      }}
    >
      {icon}
      <span>{name}</span>
    </div>
  )
}

/**
 * The navigation between speakers, teampoints and evaluation at the bottom of the screen
 */
export const BottomNavigationBar = () => {
  const {judgeId} = useSession()
  const translation = useTranslation()

  const iconSize: number = 18
  const hasJudgeId = judgeId !== undefined

  return (
    <nav
      className={"flex flex-row p-2 m-2 bg-background-300 items-center gap-x-1 rounded-full overflow-auto max-w-[100%]"}>
      <NavItem
        icon={<Home size={iconSize}/>}
        name={translation.room}
        view={"roomSetup"}
      />
      <ArrowRight size={iconSize} className={tw("mobile:hidden")}/>
      <NavItem
        icon={<AffiliationIcon affiliation={"government"} size={iconSize}/>}
        name={translation.government.substring(0, 3)}
        view={"government"}
        enabled={hasJudgeId}
      />
      <NavItem
        icon={<AffiliationIcon affiliation={"opposition"} size={iconSize}/>}
        name={translation.opposition.substring(0, 3)}
        view={"opposition"}
        enabled={hasJudgeId}
      />
      <NavItem
        icon={<AffiliationIcon affiliation={"freeSpeeches"} size={iconSize}/>}
        name={translation.freeSpeeches}
        view={"freeSpeeches"}
        enabled={hasJudgeId}
      />
      <ArrowRight size={iconSize} className={tw("mobile:hidden")}/>
      <NavItem
        icon={<Sigma size={iconSize}/>}
        name={translation.evaluation.substring(0, 4) + "."}
        view={"evaluation"}
        enabled={hasJudgeId}
      />
      <ArrowRight size={iconSize} className={tw("mobile:hidden")}/>
      <NavItem
        icon={<Table size={iconSize}/>}
        name={translation.result}
        view={"results"}
        enabled={hasJudgeId}
      />
    </nav>
  );
}
