import {HTMLAttributes} from "react";
import {tw, tx} from "@twind/core";
import {Button} from "./basic/Button";
import {Tag} from "./basic/Tag";
import {getColoring} from "../util/coloringHelper";
import {Coloring} from "../configs/colors";
import {TriangleAlert} from "lucide-react";
import {useTranslation} from "../localization/useTranslation";

type ScoreAdjudicationHeaderProps = Omit<HTMLAttributes<HTMLDivElement>, "title"> & {
  title?: string,
  titleColoring?: Coloring,
  selection?: { selected: boolean, onSelect: () => void },
  warning?: boolean,
  average?: number,
  deduction?: number,
  sumColoring?: Coloring
}

export const ScoreAdjudicationHeader = ({
                                          title,
                                          titleColoring,
                                          selection,
                                          className,
                                          warning = false,
                                          average,
                                          deduction,
                                          sumColoring
                                        }: ScoreAdjudicationHeaderProps) => {
  const translation = useTranslation()
  const hasSum = average !== undefined


  return (
    <div className={tx("flex flex-wrap gap-2 w-full", className)}>
      {selection ? (
        <Button
          isRow={true}
          coloring={titleColoring}
          size={"normal"}
          onClick={selection.onSelect}
        >
          <input
            readOnly={true}
            type={"checkbox"}
            checked={selection.selected}
            className={tw("rounded-sm cursor-pointer")}
          />
          <span className={tw("font-semibold")}>{title}</span>
        </Button>
      ) : (
        <Tag isRow={true} coloring={titleColoring} size={"normal"}>
          <span className={tw("font-semibold")}>{title}</span>
        </Tag>
      )}
      {hasSum && (
        <Tag
          isRow={true}
          className={tx("font-semibold", getColoring(sumColoring))}
          size={"normal"}
        >
          {`${translation.sum}: ${average}` + (deduction !== undefined ? ` ${deduction < 0 ? "-" : ""} ${Math.abs(deduction)} = ${average + deduction}` : "")}
        </Tag>
      )}
      {warning && (
        <Tag coloring={{color: "warning", hover: false}} isRow={true} className={tw("font-semibold")}
             size={"normal"}>
          <TriangleAlert size={18}/>
          {translation.notEvaluated}
        </Tag>
      )}
    </div>
  )
}
