import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App.tsx";
import "./style.css";
import {setup} from "@twind/core";
import twindConfig from "./configs/twind";


const root = ReactDOM.createRoot(document.getElementById("root"));
setup(twindConfig)

root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
