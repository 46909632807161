import {tw} from "@twind/core";
import {judgeChairID} from "../../types/judge";
import {Button} from "../basic/Button";
import {TriangleAlert} from "lucide-react";
import {CheckBox} from "../basic/CheckBox";
import {useTranslation} from "../../localization/useTranslation";
import {useSession} from "../../session/useSession";

type EvaluationExpansionHeaderProps = {
  expansionJudgeId: number,
  isEvaluated: boolean,
  onChangeIsEvaluated: (value: boolean) => void,
}

export const EvaluationExpansionHeader = ({
                                            expansionJudgeId,
                                            isEvaluated,
                                            onChangeIsEvaluated
                                          }: EvaluationExpansionHeaderProps) => {
  const {judgeId} = useSession()
  const translation = useTranslation()

  return (
    <div className={tw("flex flex-row justify-between w-full items-center")}>
      <span className={tw("font-semibold")}>
        {`${translation.judge(expansionJudgeId)} ${translation.details}` + (expansionJudgeId === judgeId ? ` (${translation.you})` : "")}
      </span>
      {(expansionJudgeId === judgeId || judgeId === judgeChairID) && (
        <Button
          coloring={{color: isEvaluated ? "positive" : "warning"}}
          isRow={true}
          onClick={() => onChangeIsEvaluated(!isEvaluated)}
        >
          {!isEvaluated && <TriangleAlert size={18}/>}
          {translation.visible}
          <CheckBox
            value={isEvaluated}
            onChange={() => onChangeIsEvaluated(!isEvaluated)}
          />
        </Button>
      )}
    </div>
  )
}
