import {Team} from "../../types/teamTypes";
import {useContext} from "react";
import {AppContext} from "../../appContext";
import {useTranslation} from "../../localization/useTranslation";
import {calculateInteractionScore} from "../../util/scores";
import {Card} from "../basic/Card";
import {tw, tx} from "@twind/core";
import {produce} from "immer";
import CategoryCounter from "../CategoryCounter";
import {useUpdateTeamAdjudication} from "../../api/api";
import {useMyAdjudication} from "../../util/hooks/useMyAdjudication";
import {ScoreAdjudicationHeader} from "../ScoreAdjudicationHeader";
import {Coloring} from "../../configs/colors";
import {encodingMapper} from "../../util/encodingMapper";
import {interactionCategories} from "../../types/categories";
import {LoadingDisplay} from "../LoadingDisplay";

type TeamInteractionAdjudicationProps = {
  team: Team,
}

export const TeamInteractionAdjudication = ({team}: TeamInteractionAdjudicationProps) => {
  const {config} = useContext(AppContext)
  const translation = useTranslation()
  const adjudication = useMyAdjudication()
  const updateTeamAdjudication = useUpdateTeamAdjudication()

  const teamAdjudication = !!adjudication ? adjudication[team] : undefined

  if (!adjudication || !teamAdjudication) {
    return <LoadingDisplay/>
  }

  const totalScore = calculateInteractionScore(teamAdjudication)
  // TODO fix this colorencoding
  const colorEncoding = config.teamCategory.strategy.colorEncoding.map(element =>
    ({...element, bound: element.bound * 3}))

  return (
    <Card
      title={(
        <ScoreAdjudicationHeader
          title={`${translation[team]} ${translation.interaction}`}
          titleColoring={{color: team}}
          selection={{
            selected: teamAdjudication.shouldEvaluateTeamCategories,
            onSelect: () => {
              const newTeamAdjudication = produce(adjudication[team], (draft) => {
                draft.shouldEvaluateTeamCategories = !draft.shouldEvaluateTeamCategories
              })
              updateTeamAdjudication(newTeamAdjudication, team)
            }
          }}
          average={teamAdjudication.shouldEvaluateTeamCategories ? totalScore : undefined}
          sumColoring={encodingMapper<Coloring>(totalScore, colorEncoding)}
          warning={!teamAdjudication.shouldEvaluateTeamCategories}
        />
      )}
      expansionConfig={{isExpanded: false, enabled: true, showIcon: true}}
    >
      {({isExpanded, updateConfig}) => (
        <div className={tw(`grid grid-cols-${interactionCategories.length} gap-x-2 mobileSmall:gap-x-1 mt-4`)}>
          {interactionCategories.map(category => (
            <CategoryCounter
              key={category}
              {...config.teamCategory[category]}
              largeIncrement={5}
              title={translation[category]}
              onChange={value => {
                updateTeamAdjudication(produce(teamAdjudication, draft => {
                  draft.teamCategories[category] = value
                }), team)
              }}
              value={teamAdjudication.teamCategories[category]}
              isEditable={isExpanded}
              isShowingMaximum={isExpanded}
              isShowingGrades={isExpanded}
              onClick={() => updateConfig(prevState => produce(prevState, draft => {
                draft.isExpanded = true
              }))}
              className={tx("flex flex-1", {"cursor-pointer": !isExpanded})}
            />
          ))}
        </div>
      )}
    </Card>
  )
}
