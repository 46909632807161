import {View} from "./types/views";
import {defaultOPDConfig, OPDConfig} from "./configs/opd";
import {Debate} from "./types/states";
import React, {createContext, PropsWithChildren, useEffect, useState} from "react";
import {noop} from "./util/noop";
import {useSession} from "./session/useSession";
import {produce} from "immer";
import {defaultLanguage, Languages} from "./localization/localization";
import {judgeChairID} from "./types/judge";

export type AppContextType = {
  activeView: View,
  language: Languages,
  config: OPDConfig,
  debate?: Debate,
  update: React.Dispatch<React.SetStateAction<AppContextType>>
}

const defaultAppContextType: AppContextType = {
  activeView: "start",
  language: defaultLanguage,
  config: defaultOPDConfig,
  update: noop
}

/**
 * The context for the entire application.
 *
 * It provides the user preferred language, general configuration and state of the debate
 */
export const AppContext = createContext(defaultAppContextType)

export const AppContextProvider = ({children}: PropsWithChildren) => {
  const [context, setContext] = useState<AppContextType>(defaultAppContextType)
  const {activeView, debate} = context
  const {connection, connect, judgeId, connectionStatus, updateSession} = useSession()

  // update the update handler
  useEffect(() => {
    const update = (newDebate: Debate) => {
      if(!newDebate){
        if(!!debate){
          console.error("server send empty debate state, was the debate deleted?")
        }else {
          console.warn("empty update skipped")
        }
        return
      }
      let shouldNavigate = false
      if (judgeId !== undefined) {
        // Check reselect if wing was deleted
        if (newDebate?.adjudications && newDebate.adjudications.find(value => value.judge.id === judgeId) === undefined) {
          console.warn(`${judgeId} seems to be deleted, moving you back to role selection`)
          updateSession(prevState => produce(prevState, draft => {
            draft.judgeId = undefined
          }))
          shouldNavigate = true
        }
      }
      setContext(prevState => produce(prevState, (draft) => {
        console.info("received update from server")
        draft.debate = newDebate;
        if (shouldNavigate) {
          draft.activeView = "roomSetup"
        }
        if (newDebate?.adjudications && newDebate.adjudications.length > 0) {
          // Update speaker count to stay consistent
          draft.config.freeSpeechCount = newDebate.adjudications[judgeChairID].freeSpeeches.speakers.length
          draft.config.teamSpeechCount = newDebate.adjudications[judgeChairID].government.speakers.length
        }
      }))
    }
    connection.addUpdateHandler(update)

    return () => {
      connection.removeUpdateHandler(update)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setContext])

  // initialize a connection based on the URL used
  useEffect(() => {
    setContext(prevState => produce(prevState, draft => {
      draft.update = setContext
    }))

    const href = window.location.href
    const paramStart = href.indexOf("?")
    // No params are used
    if (paramStart === -1) {
      return
    }
    const paramStrings = href.substring(paramStart + 1).split("&")
    const paramMap: Record<string, string> = {}
    for (let param of paramStrings) {
      const split = param.split("=")
      if (split.length !== 2) {
        continue
      }
      paramMap[split[0]] = split[1]
    }

    if (paramMap["room"]) {
      const room = paramMap["room"]
      const judge = paramMap["judge"]
      let judgeId: number | undefined = undefined
      if (judge && !isNaN(parseInt(judge))) {
        judgeId = parseInt(judge)
      }
      connect(room, judgeId).then(() => setContext(prevState => produce(prevState, draft => {
        draft.activeView = "roomSetup"
      })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // check navigation and fix it
  useEffect(() => {
    if (activeView === "start" && connectionStatus === "connected") {
      console.info("already connected moving you to the correct screen")
      setContext(prevState => produce(prevState, draft => {
        draft.activeView = "roomSetup"
      }))
    }
    if (judgeId === undefined && activeView !== "start" && activeView !== "roomSetup") {
      if (connectionStatus === "connected") {
        setContext(prevState => produce(prevState, draft => {
          draft.activeView = "roomSetup"
        }))
      } else {
        setContext(prevState => produce(prevState, draft => {
          draft.activeView = "start"
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionStatus, judgeId, activeView])

  return (
    <AppContext.Provider value={{...context, update: setContext}}>
      {children}
    </AppContext.Provider>
  )
}
