import {Debate, DebateAdjudication} from "../types/states";
import {
  calculateAverageScore,
  calculateInteractionScore,
  calculateSpeakerScore,
  calculateSpreadScore,
  calculateStrategyScore,
  calculateTeamCategoriesTotalScore,
  calculateTeamPersuasionScore,
  calculateTeamSpeakerTotalScore,
  calculateTeamTotalScore
} from "./scores";
import {
  DebateResult,
  InteractionResult,
  Result, ResultWithDeductions,
  Score,
  SpeakerResult,
  StrategyResult,
  TeamResult
} from "../types/results";
import {SpeakerAffiliation} from "../types/teamTypes";
import {InteractionCategory, SpeakerCategory} from "../types/categories";
import {range} from "./range";
import {isDebateValid} from "./isDebateValid";
import {sum} from "./math";

/**
 * Updates the average and spread of the given result
 */
export const createResult = (scores: Score[]): Result => {
  return {
    scores,
    average: calculateAverageScore(scores),
    spread: calculateSpreadScore(scores)
  }
}

const createScore = (debate: Debate, scoreMapper: (adjudication: DebateAdjudication) => Score): Score[] => {
  const scores: (number | undefined)[] = []
  for (let adjudication of debate.adjudications) {
    scores.push(scoreMapper(adjudication))
  }
  return scores
}

const createResultByScoreMapping = (debate: Debate, scoreMapper: (adjudication: DebateAdjudication) => Score): Result => {
  return createResult(createScore(debate, scoreMapper))
}

export const calculateSpeakerResult = (debate: Debate, affiliation: SpeakerAffiliation, position: number): SpeakerResult => {
  const result = createResultByScoreMapping(debate, adjudication => {
      const speakerState = adjudication[affiliation].speakers[position]
      return speakerState.shouldEvaluateCategories ? calculateSpeakerScore(speakerState) : undefined
    }
  )
  const subcategoryMapper = (category: SpeakerCategory): Result => {
    return createResultByScoreMapping(debate, adjudication => {
      const speakerState = adjudication[affiliation].speakers[position]
      return speakerState.shouldEvaluateCategories ? speakerState.categoryScore[category] : undefined
    })
  }

  const deductions = debate.deductions.find(value => value.affiliation === affiliation && value.position === position)?.deductions ?? []
  const deductionSum = sum(...deductions.map(value => value.penalty))
  const finalScore = result.average + deductionSum

  return {
    ...result,
    position,
    affiliation,
    subcategories: {
      linguisticPower: subcategoryMapper("linguisticPower"),
      appearance: subcategoryMapper("appearance"),
      contact: subcategoryMapper("contact"),
      expertise: subcategoryMapper("expertise"),
      judgement: subcategoryMapper("judgement")
    },
    deductionSum,
    finalScore
  }
}

export const calculateStrategyResult = (debate: Debate, affiliation: SpeakerAffiliation): StrategyResult => {
  const adjudications = debate.adjudications
  const speakerCount = adjudications[0][affiliation].speakers.length

  const speakers: Result[] = range(0, speakerCount).map(position =>
    createResultByScoreMapping(debate, adjudication => {
        const teamAdjudication = adjudication[affiliation]
        const strategy = teamAdjudication.speakers[position].strategy
        return teamAdjudication.shouldEvaluateStrategy ? strategy : undefined
      }
    ))

  const scores: Score[] = range(0, adjudications.length).map(position => {
    const teamAdjudication = adjudications[position][affiliation]
    return teamAdjudication.shouldEvaluateStrategy ? calculateStrategyScore(teamAdjudication) : undefined
  })

  return {
    ...createResult(scores),
    speakers,
  }
}

export const calculateInteractionResult = (debate: Debate, affiliation: SpeakerAffiliation): InteractionResult => {
  const result = createResultByScoreMapping(debate, adjudication => {
    const teamAdjudication = adjudication[affiliation]
    const interactions = calculateInteractionScore(teamAdjudication)
    return teamAdjudication.shouldEvaluateTeamCategories ? interactions : undefined
  })
  const subcategoryMapper = (category: InteractionCategory): Result => {
    return createResultByScoreMapping(debate, adjudication => {
      const teamAdjudication = adjudication[affiliation]
      return teamAdjudication.shouldEvaluateTeamCategories ? teamAdjudication.teamCategories[category] : undefined
    })
  }
  return {
    ...result,
    subcategories: {
      counterSpeech: subcategoryMapper("counterSpeech"),
      questions: subcategoryMapper("questions"),
      interjections: subcategoryMapper("interjections"),
    },
  }
}

export const calculatePersuasivenessResult = (debate: Debate, affiliation: SpeakerAffiliation): Result => {
  return createResultByScoreMapping(debate, adjudication => {
    const teamAdjudication = adjudication[affiliation]
    const score = calculateTeamPersuasionScore(teamAdjudication)
    return teamAdjudication.shouldEvaluatePersuasiveness ? score : undefined
  })
}

export const calculateTeamSpeakerTotalResult = (debate: Debate, affiliation: SpeakerAffiliation): ResultWithDeductions => {
  const result = createResultByScoreMapping(debate, adjudication => {
    const teamAdjudication = adjudication[affiliation]
    return calculateTeamSpeakerTotalScore(teamAdjudication)
  })

  const deductions = debate.deductions.filter(value => value.affiliation === affiliation)
  const deductionSum = sum(...deductions.map(value => sum(...value.deductions.map(value1 => value1.penalty))))
  const finalScore = result.average + deductionSum

  return {
    ...result,
    deductionSum,
    finalScore
  }
}

export const calculateTeamCategoriesTotalResult = (debate: Debate, affiliation: SpeakerAffiliation): Result => {
  return createResultByScoreMapping(debate, adjudication => {
    const teamAdjudication = adjudication[affiliation]
    return calculateTeamCategoriesTotalScore(teamAdjudication)
  })
}

export const calculateTeamTotalResult = (debate: Debate, affiliation: SpeakerAffiliation): ResultWithDeductions => {
  const result = createResultByScoreMapping(debate, adjudication => {
    const teamAdjudication = adjudication[affiliation]
    return calculateTeamTotalScore(teamAdjudication)
  })

  const deductions = debate.deductions.filter(value => value.affiliation === affiliation)
  const deductionSum = sum(...deductions.map(value => sum(...value.deductions.map(value1 => value1.penalty))))
  const finalScore = result.average + deductionSum

  return {
    ...result,
    deductionSum,
    finalScore
  }
}

export const calculateTeamResult = (debate: Debate, affiliation: SpeakerAffiliation): TeamResult => {
  const adjudications = debate.adjudications
  const speakerCount = adjudications[0][affiliation].speakers.length

  const speakers: SpeakerResult[] = range(0, speakerCount).map(value => calculateSpeakerResult(debate, affiliation, value))
  const strategy: StrategyResult = calculateStrategyResult(debate, affiliation)
  const interaction: InteractionResult = calculateInteractionResult(debate, affiliation)
  const persuasion: Result = calculatePersuasivenessResult(debate, affiliation)
  const teamTotal: ResultWithDeductions = calculateTeamTotalResult(debate, affiliation)
  const teamSpeakerTotal: ResultWithDeductions = calculateTeamSpeakerTotalResult(debate, affiliation)
  const teamCategoriesTotal: Result = calculateTeamCategoriesTotalResult(debate, affiliation)

  return {
    speakers,
    strategy,
    interaction,
    persuasion,
    teamSpeakerTotal,
    teamCategoriesTotal,
    teamTotal,
  }
}

export const calculateDebateResults = (debate: Debate): DebateResult | undefined => {
  if (!isDebateValid(debate) || debate.adjudications.length === 0) {
    return
  }

  const speakerCountFreeSpeeches = debate.adjudications[0].freeSpeeches.speakers.length

  return {
    government: calculateTeamResult(debate, "government"),
    opposition: calculateTeamResult(debate, "opposition"),
    freeSpeeches: {
      speakers: range(0, speakerCountFreeSpeeches).map(position => calculateSpeakerResult(debate, "freeSpeeches", position))
    }
  }
}

export const createTab = (result: DebateResult): SpeakerResult[] => {
  return [
    ...result.government.speakers,
    ...result.opposition.speakers,
    ...result.freeSpeeches.speakers
  ]
}
