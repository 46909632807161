import "../../style.css";
import {AppContext} from "../../appContext";
import {useContext, useState} from "react";
import {Team} from "../../types/teamTypes";
import {useTranslation} from "../../localization/useTranslation";
import {tw, tx} from "@twind/core";
import {Card} from "../basic/Card";
import CategoryCounter from "../CategoryCounter";
import {encodingMapper} from "../../util/encodingMapper";
import {Coloring} from "../../configs/colors";
import {ScoreAdjudicationHeader} from "../ScoreAdjudicationHeader";
import {useMyAdjudication} from "../../util/hooks/useMyAdjudication";
import {useResult} from "../../util/hooks/useResult";
import {produce} from "immer";
import {useUpdateTeamAdjudication} from "../../api/api";
import {findTeamAdjudication} from "../../util/debateStateHelper";
import {TeamPersuasionDisplay} from "../category_displays/TeamPersuasionDisplay";
import {useSession} from "../../session/useSession";
import {LoadingDisplay} from "../LoadingDisplay";
import {EvaluationExpansionHeader} from "./EvaluationExpansionHeader";

type TeamPersuasionEvaluationProps = {
  affiliation: Team,
}

/**
 * The component for an individual speaker. Creates five counters for team categories.
 */
const TeamPersuasionEvaluation = ({
                                    affiliation,
                                  }: TeamPersuasionEvaluationProps) => {
  const {config, debate} = useContext(AppContext)
  const {judgeId} = useSession()
  const translation = useTranslation()
  const adjudication = useMyAdjudication()
  const result = useResult()
  const [expansionJudgeId, setExpansionJudgeId] = useState(0)
  const updateTeam = useUpdateTeamAdjudication()

  if (!debate || !adjudication || !result) {
    return <LoadingDisplay/>
  }

  const persuasionResult = result[affiliation].persuasion
  const hasPersuasionResult = persuasionResult.scores.some(value => value !== undefined)
  const average = persuasionResult.average

  const teamAdjudication = findTeamAdjudication(debate, expansionJudgeId, affiliation)!


  return (
    <Card
      title={(
        <ScoreAdjudicationHeader
          title={`${translation[affiliation]} ${translation.persuasion}`}
          titleColoring={{color: affiliation}}
          average={hasPersuasionResult ? average : undefined}
          warning={!hasPersuasionResult}
          sumColoring={encodingMapper<Coloring>(average, config.totalSpeakerScoreColorEncoding)}
        />
      )}
      expansionConfig={{isExpanded: false, enabled: true, showIcon: true}}
    >
      {({isExpanded, updateConfig}) => (
        <>
          <div
            className={tx(
              `grid grid-cols-${persuasionResult.scores.length + 1} gap-x-2 mobileSmall:gap-x-1 mt-4`,
              {"mb-4": isExpanded}
            )}
          >
            {persuasionResult.scores.map((score, judgeIndex) => (
              <CategoryCounter
                key={judgeIndex}
                title={judgeIndex === judgeId ? ` (${translation.you})` : translation.judge(judgeIndex)}
                colorEncoding={config.teamCategory.persuasion.colorEncoding}
                value={score ?? 0}
                hideValue={score === undefined}
                onClick={() => {
                  updateConfig(prevState => produce(prevState, draft => {
                    draft.isExpanded = true
                  }))
                  setExpansionJudgeId(judgeIndex)
                }}
                className={tx("flex flex-1")}
              />
            ))}
            <CategoryCounter
              key={"spread"}
              colorEncoding={config.spreadColorEncoding}
              largeIncrement={0}
              title={translation.spread}
              value={persuasionResult.spread}
              className={tx("flex flex-1 border-transparent")}
            />
          </div>
          {isExpanded && (
            <div className={tw("flex flex-col items-center w-full pt-2 border-t-1")}>
              <EvaluationExpansionHeader
                expansionJudgeId={expansionJudgeId}
                isEvaluated={teamAdjudication.shouldEvaluatePersuasiveness}
                onChangeIsEvaluated={() => updateTeam(produce(teamAdjudication, (draft) => {
                  draft.shouldEvaluatePersuasiveness = !draft.shouldEvaluatePersuasiveness
                }), affiliation, expansionJudgeId)}
              />
              <TeamPersuasionDisplay
                adjudication={teamAdjudication}
                hideValue={!teamAdjudication.shouldEvaluatePersuasiveness && expansionJudgeId !== judgeId}
                isEditable={expansionJudgeId === judgeId}
                isShowingMaximum={expansionJudgeId === judgeId}
                isShowingGrades={expansionJudgeId === judgeId}
                affiliation={affiliation}
                className={tw("mt-2")}
              />
            </div>
          )}
        </>
      )}
    </Card>
  )
}
export default TeamPersuasionEvaluation;
