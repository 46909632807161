import {useContext, useState} from "react";
import {useTranslation} from "../localization/useTranslation";
import {QrCodeIcon, Unplug} from "lucide-react";
import QRCode from "react-qr-code";
import {Button} from "./basic/Button";
import {Modal} from "./modal/Modal";
import {tw} from "@twind/core";
import {useSession} from "../session/useSession";
import {AppContext} from "../appContext";

export const ConnectionInfoButton = () => {
  const {language} = useContext(AppContext)
  const translation = useTranslation(language)
  const {connectionStatus, connection} = useSession()
  const [isShowingQrCode, setIsShowingQrCode] = useState(false)

  if (connectionStatus === "disconnected" || connectionStatus === "failed") {
    return (
      <Button coloring={{color: "negative"}} className={tw("flex flex-row gap-x-1 items-center")}>
        <Unplug size={18}/>
        {translation.notConnected}
      </Button>)

  } else if (connectionStatus === "connecting") {
    return <Button coloring={{color: "primary"}}>{translation.connecting}</Button>
  }
  const sessionCode = connection.getSessionId()
  const joinLink = `http:${window.location.host}/?room=${sessionCode}&judge=0`

  return (
    <>
      <Button coloring={{color: "positive"}} onClick={() => setIsShowingQrCode(true)}>
        <div className={"flex flex-row gap-1 items-center"}>
          <QrCodeIcon size={20}/>
          <span className={tw("mobileSmall:hidden")}>{`${translation.roomCode}: `}</span>
          {sessionCode}
        </div>
      </Button>

      <Modal
        id="qrCodeModal"
        isOpen={isShowingQrCode}
        title={<h2 className={tw("text-lg font-bold")}>{`Raumcode: ${sessionCode}`}</h2>}
        onCloseClick={() => setIsShowingQrCode(false)}
        onBackgroundClick={() => setIsShowingQrCode(false)}
        modalClassName={tw("text-black-base")}
      >
        <div className={"flex flex-col items-center gap-y-4 mt-4 px-8"}>
          <div className={tw("bg-white-base p-4 rounded-xl")}>
            <QRCode value={joinLink}/>
          </div>
          <Button
            coloring={{color: "positive"}}
            onClick={() => {
              navigator.clipboard.writeText(`http:${window.location.host}/?room=${sessionCode}`);
            }}
          >
            {translation.copy}
          </Button>
        </div>
      </Modal>
    </>
  )
}
