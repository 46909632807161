import {useTranslation} from "../localization/useTranslation";
import {ViewBase} from "./ViewBase";
import {tw} from "@twind/core";
import {BookHeart, MessageCircleQuestion, Presentation, Speech} from "lucide-react";
import {speakerAffiliations, teams} from "../types/teamTypes";
import {useMyAdjudication} from "../util/hooks/useMyAdjudication";
import SpeakerEvaluation from "../components/evaluation/SpeakerEvaluation";
import TeamStrategyEvaluation from "../components/evaluation/TeamStrategyEvaluation";
import {Card} from "../components/basic/Card";
import {Divider} from "../components/basic/Divider";
import TeamInteractionEvaluation from "../components/evaluation/TeamInteractionEvaluation";
import TeamPersuasionEvaluation from "../components/evaluation/TeamPersuasionEvaluation";
import {LoadingDisplay} from "../components/LoadingDisplay";

/**
 * The EvaluationView display the averaged scores for all speakers and the team's team categories scores
 */
export const EvaluationView = () => {
  const adjudication = useMyAdjudication()
  const translation = useTranslation()

  if (!adjudication) {
    return <LoadingDisplay/>
  }

  return (
    <ViewBase className={tw("gap-y-6")}>
      <Card
        title={(
          <div className={tw("flex flex-row gap-x-2 items-center")}>
            <Speech strokeWidth={2.5} size={36}/>
            <h2 id="speaker" className={tw("text-3xl font-bold")}>{translation.speaker}</h2>
          </div>
        )}
        expansionConfig={{isExpanded: true, enabled: true, showIcon: true}}
        className={tw("!p-0 !m-0 bg-transparent")}
      >
        <div className={tw("flex flex-col gap-y-4")}>
          {speakerAffiliations.map(affiliation =>
            adjudication[affiliation].speakers.map(speaker => (
                <SpeakerEvaluation
                  key={speaker.position}
                  affiliation={affiliation}
                  position={speaker.position}
                />
              )
            ))}
        </div>
      </Card>
      <Divider/>
      <Card
        title={(
          <div className={tw("flex flex-row gap-x-2 items-center")}>
            <Presentation strokeWidth={2.5} size={36}/>
            <h2 id="strategy" className={tw("text-3xl font-bold")}>{translation.strategy}</h2>
          </div>
        )}
        expansionConfig={{isExpanded: true, enabled: true, showIcon: true}}
        className={tw("!p-0 !bg-transparent")}
      >
        <div className={tw("flex flex-col gap-y-4")}>
          {teams.map(affiliation => (
            <TeamStrategyEvaluation
              key={affiliation}
              affiliation={affiliation}
            />
          ))}
        </div>
      </Card>
      <Divider/>
      <Card
        title={(
          <div className={tw("flex flex-row gap-x-2 items-center")}>
            <MessageCircleQuestion strokeWidth={2.5} size={36}/>
            <h2 id="strategy" className={tw("text-3xl font-bold")}>{translation.interaction}</h2>
          </div>
        )}
        expansionConfig={{isExpanded: true, enabled: true, showIcon: true}}
        className={tw("!p-0 !bg-transparent")}
      >
        <div className={tw("flex flex-col gap-y-4")}>
          <div className={tw("flex flex-col gap-y-4")}>
            {teams.map(affiliation => (
              <TeamInteractionEvaluation
                key={affiliation}
                affiliation={affiliation}
              />
            ))}
          </div>
        </div>
      </Card>
      <Divider/>
      <Card
        title={(
          <div className={tw("flex flex-row gap-x-2 items-center")}>
            <BookHeart strokeWidth={2.5} size={36}/>
            <h2 id="strategy" className={tw("text-3xl font-bold")}>{translation.persuasion}</h2>
          </div>
        )}
        expansionConfig={{isExpanded: true, enabled: true, showIcon: true}}
        className={tw("!p-0 !bg-transparent")}
      >
        <div className={tw("flex flex-col gap-y-4")}>
          {teams.map(affiliation => (
            <TeamPersuasionEvaluation
              key={affiliation}
              affiliation={affiliation}
            />
          ))}
        </div>
      </Card>
    </ViewBase>
  )
}

export default EvaluationView;
