import {Encoding} from "../types/encoding";
import {PointTypes} from "../types/pointTypes";

/**
 * A grade encoding mapping number intervals to indexes
 */
export type GradesEncoding = Encoding<number>

const breakPointsToGrades = (breakPoints: number[]): GradesEncoding => {
  if (breakPoints.length !== 18) {
    console.error(`breakPointsToGrades: there must be 18 breakpoints, got ${breakPoints.length}`)
    return []
  }

  return breakPoints.map((value, index) => ({
    bound: value,
    value: index
  }))
}

export const defaultGradesEncoding: Record<PointTypes, GradesEncoding> = {
  points15: breakPointsToGrades([0, 1, 1, 2, 3, 4, 4, 5, 6, 7, 7, 8, 9, 10, 10, 11, 12, 14]),
  points20: breakPointsToGrades([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19]),
  points25: breakPointsToGrades([ 0, 1, 2, 4, 5, 6, 7, 9, 10, 11, 12, 14, 15, 16, 17, 19, 20, 24]),
  points30: breakPointsToGrades([0, 1, 3, 4, 6, 7, 9, 10, 12, 13, 15, 16, 18, 19, 21, 22, 24, 28]),
  points50: breakPointsToGrades([0, 1, 4, 7, 9, 12, 14, 17, 19, 22, 24, 27, 29, 32, 34, 37, 39, 47]),
  // TODO update
  points75: breakPointsToGrades([0, 1, 4, 7, 9, 12, 14, 17, 19, 22, 24, 27, 29, 32, 34, 37, 39, 47]),
  // TODO update
  points100: breakPointsToGrades([0, 1, 4, 7, 9, 12, 14, 17, 19, 22, 24, 27, 29, 32, 34, 37, 39, 47]),
}
