import {useContext} from "react";
import {useTranslation} from "../localization/useTranslation";
import {useUpdateDebate} from "../api/api";
import {ViewBase} from "./ViewBase";
import {tw} from "@twind/core";
import {JudgeManagement} from "../components/JudgeManagement";
import {Card} from "../components/basic/Card";
import {judgeChairID} from "../types/judge";
import {Button} from "../components/basic/Button";
import {Input} from "../components/basic/Input";
import {produce} from "immer";
import {initializeSpeakerAdjudication} from "../util/initializeDebate";
import {teams} from "../types/teamTypes";
import {useSession} from "../session/useSession";
import {LoadingDisplay} from "../components/LoadingDisplay";
import {AppContext} from "../appContext";


/**
 * The RoomSetupView displays the different roles an adjudicator can have.
 *
 * The chair can rename and delete adjudicators. Adding adjudicators is allowed by everyone.
 *
 * The chair can also change the number of speakers in the teams or the number of free speeches
 */
export const RoomSetupView = () => {
  const context = useContext(AppContext)
  const {config, update, debate} = context
  const {judgeId} = useSession()
  const translation = useTranslation()
  const updateDebate = useUpdateDebate()

  if (!debate) {
    return <LoadingDisplay/>
  }

  const changeSpeechCount = (value: number, isTeam: boolean) => {
    if (value < 1) {
      return
    }
    const newContext = produce(context, (draft) => {
      draft.config = {
        ...draft.config,
        teamSpeechCount: isTeam ? value : draft.config.teamSpeechCount,
        freeSpeechCount: !isTeam ? value : draft.config.freeSpeechCount,
      }
    })
    update(newContext)
    const newConfig = newContext.config
    updateDebate(produce(debate, (draft) => {
      const affiliations = isTeam ? teams : ["freeSpeeches"] as const
      // TODO make this more robust
      draft.adjudications.forEach(adjudication => {
        for (let speakerAffiliation of affiliations) {
          adjudication[speakerAffiliation].speakers = adjudication[speakerAffiliation].speakers.filter(speaker => speaker.position < value)
          for (let i = adjudication[speakerAffiliation].speakers.length; adjudication[speakerAffiliation].speakers.length < value; i++) {
            adjudication[speakerAffiliation].speakers.push(initializeSpeakerAdjudication(newConfig, i))
          }
        }
      })
    }))
  }

  return (
    <ViewBase className={tw("gap-y-8 !max-w-[600px]")}>
      <Card
        className={tw("!bg-background-50 text-black-base")}
        title={<h2 className={tw("font-bold text-2xl")}>{translation.whoYou}</h2>}
        expansionConfig={{isExpanded: true, enabled: true, showIcon: true}}
      >
        <JudgeManagement maxWidth={"600px"} className={tw("pt-4")}/>
      </Card>
      {judgeId === judgeChairID && (
        <Card
          className={tw("!bg-background-50 text-black-base")}
          title={<h2 className={tw("font-bold text-2xl")}>{translation.roomSetting}</h2>}
          expansionConfig={{isExpanded: true, enabled: true, showIcon: true}}
        >
          <span className={tw("text-gray-600 mt-2")}>{translation.roomSettingsWarning}</span>
          <span className={tw("font-semibold text-xl underline mb-4 mt-2")}>{translation.speechCount}</span>
          <div className={tw("flex flex-row justify-between items-center")}>
            <span className={tw("text-lg font-semibold")}>{translation.team}</span>
            <div className={tw("flex flex-row justify-between gap-x-2")}>
              <Button
                coloring={{color: "white"}}
                size={"small"}
                className={tw("min-w-[50px]")}
                onClick={() => {
                  changeSpeechCount(config.teamSpeechCount - 1, true)
                }}
              >
                -1
              </Button>
              <Input
                value={config.teamSpeechCount.toString()}
                readOnly={true}
                className={tw("max-w-[100px] text-center")}
              />
              <Button
                coloring={{color: "white"}}
                size={"small"}
                className={tw("min-w-[50px]")}
                onClick={() => {
                  changeSpeechCount(config.teamSpeechCount + 1, true)
                }}
              >
                +1
              </Button>
            </div>
          </div>
          <div
            className={tw("flex flex-row justify-between items-center mt-2")}
          >
            <span className={tw("text-lg font-semibold")}>{translation.freeSpeeches}</span>
            <div className={tw("flex flex-row justify-between gap-x-2")}>
              <Button
                coloring={{color: "white"}}
                size={"small"}
                className={tw("min-w-[50px]")}
                onClick={() => {
                  changeSpeechCount(config.freeSpeechCount - 1, false)
                }}
              >
                -1
              </Button>
              <Input
                value={config.freeSpeechCount.toString()}
                readOnly={true}
                className={tw("max-w-[100px] text-center")}
              />
              <Button
                coloring={{color: "white"}}
                size={"small"}
                className={tw("min-w-[50px]")}
                onClick={() => {
                  changeSpeechCount(config.freeSpeechCount + 1, false)
                }}
              >
                +1
              </Button>
            </div>
          </div>
        </Card>
      )}
    </ViewBase>
  )
}

export default RoomSetupView;
