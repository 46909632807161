import {Debate} from "../types/states";
import {speakerAffiliations, SpeakerAffiliation} from "../types/teamTypes";

/**
 * A method checking whether a debate is valid
 *
 * It regards whether all judges have the same number of speakers for affiliation
 */
export const isDebateValid = (debate: Debate): boolean => {
  const speakerCounts: Record<SpeakerAffiliation, number> = {
    government: -1,
    opposition: -1,
    freeSpeeches: -1
  }

  if (debate.adjudications.length === 0) {
    console.warn("The debate does not contain a adjudication. This means it either is not initialized or it is an error.")
    return true
  }
  for (let adjudication of debate.adjudications) {
    for (let affiliation of speakerAffiliations) {
      // initialization case
      if (speakerCounts[affiliation] === -1) {
        speakerCounts[affiliation] = adjudication[affiliation].speakers.length
        continue
      }
      // check validity
      if (speakerCounts[affiliation] !== adjudication[affiliation].speakers.length) {
        console.error(`Judge ${adjudication.judge.id} has a non conforming speaker count of
        ${adjudication[affiliation].speakers.length} opposed to ${speakerCounts[affiliation]} for ${affiliation}`)
        return false
      }
    }
  }
  return true
}
