import {HTMLAttributes} from "react";
import {Coloring} from "../../configs/colors";
import {tx} from "@twind/core";
import {getColoring} from "../../util/coloringHelper";

type TagSize = "small" | "normal" | "large"

type TagProps = HTMLAttributes<HTMLDivElement> & {
  size?: TagSize
  coloring?: Coloring,
  isRow?: boolean,
}

export const Tag = ({
                      children,
                      size = "small",
                      coloring,
                      isRow,
                      className,
                      ...restProps
                    }: TagProps) => {
  const styleMapping: Record<TagSize, string> = {
    small: "px-2 py-1 rounded-md",
    normal: "px-4 py-2 rounded-md",
    large: "px-8 py-4 text-lg rounded-lg"
  }

  return (
    <div
      {...restProps}
      className={tx(
        styleMapping[size],
        coloring ? getColoring(coloring) : "",
        {"flex flex-row gap-x-2 justify-center items-center": isRow},
        className)
      }
    >
      {children}
    </div>
  )
}
