import {useState} from "react";

export const useOnce = (executor: () => void) => {
  const [isUsed, setIsUsed] = useState(false)

  return () => {
    if(isUsed){
      return
    }
    setIsUsed(false)
    executor()
  }
}

export const useOnceList = <T>(executor: (value: T) => void) => {
  const [entries, setEntries] = useState<T[]>([])
  const limit = 100
  const once = useOnce(() => console.log(`useOnceList entry list has more than ${limit} entries. Keep your entries equal to avoid this`))

  return (entry: T) => {
    if(!entries.includes(entry)){
      executor(entry)
      setEntries(prevState => [...prevState, entry])
    }
    if(entries.length > limit){
      once()
    }
  }
}

export const useInfoOnce = () => {
  return useOnceList<string>(console.warn)
}

export const useWarnOnce = () => {
  return useOnceList<string>(console.warn)
}

export const useErrorOnce = () => {
  return useOnceList<string>(console.error)
}
