import {SpeakerAdjudication, TeamAdjudication} from "../types/states";
import {onlyWhenEvaluatedNumber} from "./onlyWhenEvaluated";
import {round} from "./math";

export const calculateSpeakerScore = (state: SpeakerAdjudication) => {
  const categoryScore = state.categoryScore
  return onlyWhenEvaluatedNumber(
    categoryScore.linguisticPower
    + categoryScore.appearance
    + categoryScore.contact
    + categoryScore.expertise
    + categoryScore.judgement
    , state.shouldEvaluateCategories
  )
}

export const calculateTeamSpeakerTotalScore = (state: TeamAdjudication) => {
  let sum = 0
  state.speakers.forEach(value => sum += calculateSpeakerScore(value))
  return sum
}

export const calculateStrategyScore = (state: TeamAdjudication) => {
  let sum: number = 0
  state.speakers.forEach(value => sum += value.strategy)
  return onlyWhenEvaluatedNumber(sum, state.shouldEvaluateStrategy)
}

export const calculateInteractionScore = (state: TeamAdjudication) => {
  return onlyWhenEvaluatedNumber(
    state.teamCategories.counterSpeech
    + state.teamCategories?.questions
    + state.teamCategories?.interjections
    , state.shouldEvaluateTeamCategories
  )
}

export const calculateTeamPersuasionScore = (state: TeamAdjudication) => {
  return onlyWhenEvaluatedNumber(state.teamCategories.persuasion, state.shouldEvaluatePersuasiveness)
}

export const calculateTeamCategoriesTotalScore = (state: TeamAdjudication) => {
  return calculateStrategyScore(state) + calculateInteractionScore(state) + calculateTeamPersuasionScore(state)
}

export const calculateTeamTotalScore = (state: TeamAdjudication) => {
  return calculateTeamCategoriesTotalScore(state) + calculateTeamSpeakerTotalScore(state)
}

export const calculateAverageScore = (values: (number | undefined)[]): number => {
  const filtered: number[] = values.filter(value => value !== undefined) as number[]
  if (filtered.length === 0) {
    return 0
  }
  let sum: number = 0
  filtered.forEach(value => sum += value)
  return round(sum / filtered.length, 2)
}

export const calculateSpreadScore = (values: (number | undefined)[]): number => {
  const filtered: number[] = values.filter(value => value !== undefined) as number[]
  if (filtered.length === 0) {
    return 0
  }
  return Math.max(...filtered) - Math.min(...filtered)
}
