import React, {createContext, PropsWithChildren, useEffect, useState} from "react";
import {ServerConnection} from "./connection";
import {Debate} from "../types/states";
import {noop} from "../util/noop";
import {produce} from "immer";

export type SessionContextType = {
  judgeId?: number,
  connection: ServerConnection<Debate, any>,
  updateSession: React.Dispatch<React.SetStateAction<SessionContextType>>
}

export const defaultSessionContext: SessionContextType = {
  connection: new ServerConnection(),
  updateSession: noop
}

export const SessionContext = createContext(defaultSessionContext)

export const SessionContextProvider = ({children}: PropsWithChildren) => {
  const [session, setSession] = useState<SessionContextType>(defaultSessionContext)

  // initialize update function
  useEffect(() => setSession(prevState => produce(prevState, draft => {
    draft.updateSession = setSession
  })), [])

  const sessionId = session.connection.getSessionId()

  // Always keep the url updated
  useEffect(() => {
    const judgeId = session.judgeId
    window.history.replaceState(
      null,
      "OPD Online",
      (!!sessionId ? `/?room=${sessionId}` : "") + (judgeId !== undefined ? `&judge=${judgeId}` : "")
    )
  }, [session.judgeId, sessionId])

  return (
    <SessionContext.Provider value={{...session, updateSession: setSession}}>
      {children}
    </SessionContext.Provider>
  )
}

