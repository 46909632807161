import {HTMLAttributes} from "react";
import {tx} from "@twind/core";
import {noop} from "../../util/noop";

type CheckBoxProps = Omit<HTMLAttributes<HTMLInputElement>, "onChange"| "type"> & {
  value?: boolean,
  onChange?: (value: boolean) => void
}

export const CheckBox = ({value = false, onChange = noop, className, ...props}:CheckBoxProps) => {
  return (
    <input
      {...props}
      type={"checkbox"}
      checked={value}
      onChange={() => onChange(!value)}
      className={tx("rounded-sm")}
    />
  )
}
