import {HTMLAttributes, useContext} from "react";
import CategoryCounter, {CategoryCounterProps} from "../CategoryCounter";
import {TeamAdjudication} from "../../types/states";
import {Team} from "../../types/teamTypes";
import {AppContext} from "../../appContext";
import {useTranslation} from "../../localization/useTranslation";
import {useUpdateTeamAdjudication} from "../../api/api";
import {tx} from "@twind/core";
import {produce} from "immer";
import {Grid} from "../basic/Grid";

type TeamPersuasionDisplayProps = HTMLAttributes<HTMLDivElement>
  & Pick<CategoryCounterProps, "hideValue" | "isEditable" | "isShowingMaximum" | "isShowingGrades" | "largeIncrement">
  & {
  adjudication: TeamAdjudication,
  affiliation: Team,
  onCategoryClick?: () => void
}

export const TeamPersuasionDisplay = ({
                                        affiliation,
                                        adjudication,
                                        isEditable,
                                        hideValue,
                                        isShowingGrades,
                                        isShowingMaximum,
                                        largeIncrement = 5,
                                        onCategoryClick,
                                        ...props
                                      }: TeamPersuasionDisplayProps) => {
  const {config} = useContext(AppContext)
  const translation = useTranslation()
  const update = useUpdateTeamAdjudication()

  return (
    <Grid {...props} colCount={1}>
      <CategoryCounter
        key={"persuasion"}
        {...config.teamCategory.persuasion}
        title={translation.persuasion}
        value={adjudication.teamCategories.persuasion}
        hideValue={hideValue}
        isEditable={isEditable}
        isShowingMaximum={isShowingMaximum}
        isShowingGrades={isShowingGrades}
        largeIncrement={largeIncrement}
        onChange={value => {
          update(produce(adjudication, (draft) => {
            draft.teamCategories.persuasion = value
          }), affiliation)
        }}
        onClick={onCategoryClick ? () => onCategoryClick() : undefined}
        className={tx("flex flex-1")}
      />
    </Grid>
  )
}
