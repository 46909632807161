import {HTMLAttributes, useContext} from "react";
import CategoryCounter, {CategoryCounterProps} from "../CategoryCounter";
import {SpeakerAdjudication} from "../../types/states";
import {SpeakerAffiliation} from "../../types/teamTypes";
import {speakerCategories, SpeakerCategory} from "../../types/categories";
import {AppContext} from "../../appContext";
import {useTranslation} from "../../localization/useTranslation";
import {useUpdateSpeakerAdjudication} from "../../api/api";
import {tx} from "@twind/core";
import {produce} from "immer";

type SpeakerCategoriesDisplayProps =
  HTMLAttributes<HTMLDivElement>
  & Pick<CategoryCounterProps, "hideValue" | "isEditable" | "isShowingMaximum" | "isShowingGrades" | "largeIncrement">
  & {
  speakerAdjudication: SpeakerAdjudication,
  affiliation: SpeakerAffiliation,
  onCategoryClick?: (category: SpeakerCategory) => void
}

export const SpeakerCategoriesDisplay = ({
                                           affiliation,
                                           speakerAdjudication,
                                           className,
                                           isEditable,
                                           hideValue,
                                           isShowingGrades,
                                           isShowingMaximum,
                                           largeIncrement = 3,
                                           onCategoryClick,
                                           ...props
                                         }: SpeakerCategoriesDisplayProps) => {
  const {config} = useContext(AppContext)
  const translation = useTranslation()
  const updateSpeaker = useUpdateSpeakerAdjudication()

  return (
    <div
      {...props}
      className={tx(`grid grid-cols-${speakerCategories.length} gap-x-2 mobileSmall:gap-x-1 w-full`, className)}
    >
      {speakerCategories.map(category => (
        <CategoryCounter
          key={category}
          {...config.speakerCategory[category]}
          title={translation.speakerCategoryShort(category)}
          value={speakerAdjudication.categoryScore[category]}
          hideValue={hideValue}
          isEditable={isEditable}
          isShowingMaximum={isShowingMaximum}
          isShowingGrades={isShowingGrades}
          largeIncrement={largeIncrement}
          onChange={value => {
            updateSpeaker(produce(speakerAdjudication, (draft) => {
              draft.categoryScore[category] = value
            }), affiliation)
          }}
          onClick={onCategoryClick ? () => onCategoryClick(category) : undefined}
          className={tx("flex flex-1")}
        />
      ))}
    </div>
  )
}
