import "../../style.css";
import {AppContext} from "../../appContext";
import {useContext} from "react";
import {SpeakerAdjudication} from "../../types/states";
import {SpeakerAffiliation} from "../../types/teamTypes";
import {useTranslation} from "../../localization/useTranslation";
import {calculateSpeakerScore} from "../../util/scores";
import {tw, tx} from "@twind/core";
import {Card} from "../basic/Card";
import {speakerCategories} from "../../types/categories";
import CategoryCounter from "../CategoryCounter";
import {produce} from "immer";
import {encodingMapper} from "../../util/encodingMapper";
import {Coloring} from "../../configs/colors";
import {ScoreAdjudicationHeader} from "../ScoreAdjudicationHeader";

type SpeakerProps = {
  speakerType: SpeakerAffiliation,
  speakerAdjudication: SpeakerAdjudication,
  onUpdate: (speakerAdjudication: SpeakerAdjudication) => void
  isEditable?: boolean,
}

/**
 * The component for an individual speaker. Creates five counters for team categories.
 */
const SpeakerScoreAdjudication = ({
                                    speakerType,
                                    speakerAdjudication,
                                    onUpdate,
                                  }: SpeakerProps) => {
  const {config} = useContext(AppContext)
  const translation = useTranslation()

  const totalScore = calculateSpeakerScore(speakerAdjudication)

  const speakerName = `${translation.speech(speakerAdjudication.position)}` + (!!speakerAdjudication.name ? ` (${speakerAdjudication.name})` : "")

  return (
    <Card
      title={(
        <ScoreAdjudicationHeader
          title={`${translation[speakerType]} ${speakerName}`}
          titleColoring={{color: speakerType}}
          selection={{
            selected: speakerAdjudication.shouldEvaluateCategories,
            onSelect: () => onUpdate(produce(speakerAdjudication, (draft) => {
              draft.shouldEvaluateCategories = !speakerAdjudication.shouldEvaluateCategories
            }))
          }}
          average={speakerAdjudication.shouldEvaluateCategories ? totalScore : undefined}
          sumColoring={encodingMapper<Coloring>(totalScore, config.totalSpeakerScoreColorEncoding)}
          warning={!speakerAdjudication.shouldEvaluateCategories}
        />
      )}
      expansionConfig={{isExpanded: false, enabled: true, showIcon: true}}
    >
      {({isExpanded, updateConfig}) => (
        <div className={tw("grid grid-cols-5 gap-x-2 mobileSmall:gap-x-1 mt-4")}>
          {speakerCategories.map(category => (
            <CategoryCounter
              key={category}
              {...config.speakerCategory[category]}
              largeIncrement={3}
              title={translation.speakerCategoryShort(category)}
              onChange={value => {
                onUpdate(produce(speakerAdjudication, (draft) => {
                  draft.categoryScore[category] = value
                }))
              }}
              value={speakerAdjudication.categoryScore[category]}
              isEditable={isExpanded}
              isShowingMaximum={isExpanded}
              isShowingGrades={isExpanded}
              onClick={() => updateConfig(prevState => produce(prevState, (draft => {
                draft.isExpanded = true
              })))}
              className={tx("flex flex-1", {"cursor-pointer": !isExpanded})}
            />
          ))}
        </div>
      )}
    </Card>
  )
}
export default SpeakerScoreAdjudication;
