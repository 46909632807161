import {useContext} from "react";
import {findAdjudication} from "../debateStateHelper";
import {useSession} from "../../session/useSession";
import {AppContext} from "../../appContext";

export const useMyAdjudication = (judgeId?: number) => {
  const {debate} = useContext(AppContext)
  const {judgeId: myJudgeId} = useSession()
  const usedJudgeId = judgeId ?? myJudgeId
  if (!debate || usedJudgeId === undefined) {
    return undefined
  }
  return findAdjudication(debate, usedJudgeId)
}
