import {backendUrl} from "../configs/api";
import {Debate, SpeakerAdjudication, TeamAdjudication} from "../types/states";
import {compare} from "fast-json-patch/commonjs/duplex";
import {Operation} from "fast-json-patch/commonjs/core";
import {useContext} from "react";
import {AppContext} from "../appContext";
import {judgeChairID} from "../types/judge";
import {initializeDebate} from "../util/initializeDebate";
import {produce} from "immer";
import {SpeakerAffiliation} from "../types/teamTypes";
import {findAdjudication, findSpeakerDeduction} from "../util/debateStateHelper";
import {useSession} from "../session/useSession";
import {Deduction} from "../types/deduction";

/**
 * Opens a new debate session and returns its identifier
 */
export const useCreateNewRoom = () => {
  const {connect} = useSession()
  const {update} = useContext(AppContext)
  const post = usePostJSON()

  return async () => {
    console.info("creating room");
    const requestURL = backendUrl + "api/v1/addSession";

    let response = await fetch(requestURL, {
      method: "POST",
    });
    const sessionID = await response.text()
    await connect(sessionID, judgeChairID)
    console.info("initializing debate as chair")
    await post(initializeDebate())
    update(prevState => produce(prevState, (draft) => {draft.activeView = "roomSetup"}))
    return sessionID
  }
}

export const usePostJSON = () => {
  const {connection} = useSession()
  return (replacement: any) => connection.sendReplacement(replacement);
}

/**
 * updates the debate state partially
 */
export const useUpdateWithPatch = () => {
  const {connection} = useSession()
  return (updates: Operation[]) => connection.sendPartialUpdate(updates);
}

export const useUpdateDebate = () => {
  const {debate} = useContext(AppContext)
  const update = useUpdateWithPatch()

  return async (newDebate: Debate) => {
    if (!debate) {
      console.error("useUpdateDebate called before debate was received")
      return
    }
    return update(compare(debate, newDebate))
  }
}

export const useUpdateTeamAdjudication = () => {
  const {debate} = useContext(AppContext)
  const {judgeId: myJudgeId} = useSession()

  const update = useUpdateDebate()
  return (newTeamAdjudication: TeamAdjudication, affiliation: SpeakerAffiliation, judgeId?: number) => {
    const usedJudgeId = judgeId ?? myJudgeId
    if (usedJudgeId === undefined) {
      console.error("useUpdateTeamAdjudication: can only be used when a judgeId is provided")
      return
    }
    if (!debate) {
      console.error("useUpdateTeamAdjudication: can only be used when the debate is initialized")
      return
    }
    const newDebate: Debate = produce(debate, draft => {
      const adjudication = findAdjudication(draft, usedJudgeId)
      if (!adjudication) {
        console.error(`useUpdateTeamAdjudication: could not find adjudication for judge: ${usedJudgeId}`)
        return
      }
      adjudication[affiliation] = newTeamAdjudication
    })
    update(newDebate)
  }
}

export const useUpdateSpeakerAdjudication = () => {
  const {debate} = useContext(AppContext)
  const {judgeId: myJudgeId} = useSession()
  const update = useUpdateDebate()
  return (newSpeakerAdjudication: SpeakerAdjudication, affiliation: SpeakerAffiliation, judgeId?: number) => {
    const usedJudgeId = judgeId ?? myJudgeId
    if (usedJudgeId === undefined) {
      console.error("useUpdateTeamAdjudication: can only be used when a judgeId is provided")
      return
    }
    if (!debate) {
      console.error("useUpdateTeamAdjudication: can only be used when the debate is initialized")
      return
    }
    const newDebate: Debate = produce(debate, draft => {
      const adjudication = findAdjudication(draft, usedJudgeId)
      if (!adjudication) {
        console.error(`useUpdateTeamAdjudication: could not find adjudication for judge: ${usedJudgeId}`)
        return
      }
      adjudication[affiliation].speakers = adjudication[affiliation].speakers.map(value => {
        if (value.position !== newSpeakerAdjudication.position) {
          return value
        }
        return produce(newSpeakerAdjudication, (draft) => {
          draft.shouldEvaluateCategories = draft.shouldEvaluateCategories || !value.shouldEvaluateCategories
        })
      })
    })
    update(newDebate)
  }
}

export const useUpdateSpeakerDeductions = () => {
  const {debate} = useContext(AppContext)
  const update = useUpdateDebate()

  return (newDeductions: Deduction[], affiliation: SpeakerAffiliation, position: number) => {
    if (!debate) {
      console.error("useUpdateTeamAdjudication: can only be used when the debate is initialized")
      return
    }
    const newDebate: Debate = produce(debate, draft => {
      let speakerDeduction = findSpeakerDeduction(draft, affiliation, position)
      if (!speakerDeduction) {
        speakerDeduction = {
          affiliation,
          position,
          deductions: newDeductions
        }
        draft.deductions.push(speakerDeduction)
      } else {
        speakerDeduction.deductions = newDeductions
      }
    })
    update(newDebate)
  }
}
