import {tw} from "@twind/core";
import {JudgeManagement} from "../JudgeManagement";
import {Modal, ModalProps} from "./Modal";
import {useTranslation} from "../../localization/useTranslation";
import {noop} from "../../util/noop";

export type JudgeManagementModalProps = Omit<ModalProps, "title">
export const JudgeManagementModal = ({onCloseClick = noop,...props}: JudgeManagementModalProps) => {
  const translation = useTranslation()

  return (
    <Modal
      {...props}
      title={<h2 className={tw("text-lg font-semibold")}>{translation.whoYou}</h2>}
      modalClassName={tw("min-w-[300px]")}
      onCloseClick={onCloseClick}
    >
      <JudgeManagement
        className={tw("pt-4")}
        onJudgeSelect={(judgeId, handler) => {
          onCloseClick()
          handler(judgeId)
        }}
      />
    </Modal>
  )
}
