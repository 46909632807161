import {useContext, useEffect, type MouseEventHandler, type PropsWithChildren, type ReactNode} from 'react'
import ReactDOM from 'react-dom'
import {X} from 'lucide-react'
import {ModalContext} from './ModalRegister'
import {useTranslation} from "../../localization/useTranslation";
import {tx} from "@twind/core";

export type ModalHeaderProps = {
  onCloseClick?: () => void,
  title?: ReactNode,
  description?: ReactNode,
}

/**
 * A default Header to be used by modals to have a uniform design
 */
export const ModalHeader = ({
                              onCloseClick,
                              title,
                              description,
                            }: ModalHeaderProps) => {
  const translation = useTranslation()
  return (
    <div className={'flex flex-col'}>
      <div className={'flex flex-row justify-between items-start gap-x-8'}>
        {title}
        {!!onCloseClick && (
          <button className={'flex flex-row gap-x-2'} onClick={onCloseClick}>
            <span className={'mobile:hidden'}>{translation.close}</span>
            <X/>
          </button>
        )}
      </div>
      {description}
    </div>
  )
}

export const modalRootName = 'modal-root'

export type ModalProps = {
  id: string,
  isOpen: boolean,
  onBackgroundClick?: MouseEventHandler<HTMLDivElement>,
  backgroundClassName?: string,
  modalClassName?: string
} & ModalHeaderProps

/**
 * A Generic Modal Window
 *
 * The state needs to be managed by the parent of this component
 *
 * DO NOT Conditionally render this always use the isOpen to ensure that the ModalRegister is working properly
 */
export const Modal = ({
                        children,
                        id,
                        isOpen,
                        onBackgroundClick,
                        backgroundClassName = '',
                        modalClassName = '',
                        ...modalHeaderProps
                      }: PropsWithChildren<ModalProps>) => {
  const modalRoot = typeof window !== 'undefined' ? document.getElementById(modalRootName) : null
  const {
    register,
    addToRegister,
    removeFromRegister
  } = useContext(ModalContext)

  if (!id) {
    console.error('the id cannot be empty')
  }

  useEffect(() => {
    if (isOpen) {
      addToRegister(id)
    } else {
      removeFromRegister(id)
    }
  }, [addToRegister, id, removeFromRegister, isOpen])

  if (modalRoot === null || !isOpen) return null

  const isLast = register.length < 1 || register[register.length - 1] === id
  const isSecondLast = register.length < 2 || register[register.length - 2] === id

  return ReactDOM.createPortal(
    <div className={'fixed inset-0 overflow-y-auto z-[99]'} id={id}>
      {isLast && (
        <div
          className={tx('fixed inset-0 h-screen w-screen', backgroundClassName, {
            'bg-black-base/70': isLast && register.length === 1,
          })}
          onClick={onBackgroundClick}
        />
      )}
      <div
        className={tx('fixed left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 flex flex-col p-4 bg-background-50 text-black-base rounded-xl shadow-xl max-w-[90%] max-h-[90%]', modalClassName)}
      >
        <ModalHeader {...modalHeaderProps} />
        {children}
      </div>
      {!isLast && (
        <div
          className={tx('fixed inset-0 h-screen w-screen', backgroundClassName, {
            'bg-black-base/70': isSecondLast && register.length > 1,
          })}
        />
      )}
    </div>,
    modalRoot,
    id
  )
}
