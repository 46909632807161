import {
  DebateAdjudication,
  Debate,
  SpeakerAdjudication,
  TeamAdjudication,
} from "../types/states";
import {defaultOPDConfig, OPDConfig} from "../configs/opd";
import {range} from "./range";
import {Judge, judgeChairID} from "../types/judge";
import {SpeakerAffiliation} from "../types/teamTypes";

export const initializeSpeakerAdjudication = (config: OPDConfig, position: number): SpeakerAdjudication => {
  const categoryDefaults = config.speakerCategory
  return {
    position,
    categoryScore: {
      linguisticPower: categoryDefaults.linguisticPower.defaultValue,
      appearance: categoryDefaults.appearance.defaultValue,
      contact: categoryDefaults.contact.defaultValue,
      expertise: categoryDefaults.expertise.defaultValue,
      judgement: categoryDefaults.judgement.defaultValue,
    },
    shouldEvaluateCategories: false,
    strategy: config.teamCategory.strategy.defaultValue
  }
}

export const initializeTeamAdjudication = (config: OPDConfig, affiliation: SpeakerAffiliation): TeamAdjudication => {
  const teamCategories = config.teamCategory
  const speakerCount = affiliation === "freeSpeeches" ? config.freeSpeechCount : config.teamSpeechCount

  return {
    speakers: range(0, speakerCount).map(value => initializeSpeakerAdjudication(config, value)),
    teamCategories: {
      counterSpeech: teamCategories.counterSpeech.defaultValue,
      questions: teamCategories.questions.defaultValue,
      interjections: teamCategories.interjections.defaultValue,
      persuasion: teamCategories.persuasion.defaultValue
    },
    shouldEvaluateTeamCategories: false,
    shouldEvaluateStrategy: false,
    shouldEvaluatePersuasiveness: false,
  }
}

export const initializeDebateAdjudication = (config: OPDConfig, judge: Judge): DebateAdjudication => {
  return {
    judge,
    government: initializeTeamAdjudication(config, "government"),
    opposition: initializeTeamAdjudication(config, "opposition"),
    freeSpeeches: initializeTeamAdjudication(config, "freeSpeeches")
  }
}

export const initializeDebate = (config: OPDConfig = defaultOPDConfig): Debate => {
  return {
    adjudications: [initializeDebateAdjudication(config, {id: judgeChairID})],
    deductions: []
  }
}
