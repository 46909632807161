import {SimpleColorType} from "../../configs/colors";
import {tx} from "@twind/core";

export type DividerProps = {
  color?: SimpleColorType,
  className?: string,
  height?: number
}
export const Divider = ({color = "white", className, height = 1}: DividerProps) => {
  return (
    <div className={tx(`w-full border border-[${height}] border-dashed border-spacing-20 border-${color}-base`, className)} />
  )
}
