/**
 * The different teams in a debate
 */
export const teams = ["government", "opposition"] as const

export type Team = typeof teams[number]

/**
 * The different affiliations a speaker can have
 */
export const speakerAffiliations = [...teams, "freeSpeeches"] as const

export type SpeakerAffiliation = typeof speakerAffiliations[number]
