import {HTMLAttributes, useContext} from "react";
import CategoryCounter, {CategoryCounterProps} from "../CategoryCounter";
import {TeamAdjudication} from "../../types/states";
import {Team} from "../../types/teamTypes";
import {interactionCategories, InteractionCategory} from "../../types/categories";
import {AppContext} from "../../appContext";
import {useTranslation} from "../../localization/useTranslation";
import {useUpdateTeamAdjudication} from "../../api/api";
import {tx} from "@twind/core";
import {produce} from "immer";

type TeamStrategyDisplayProps = HTMLAttributes<HTMLDivElement>
  & Pick<CategoryCounterProps, "hideValue" | "isEditable" | "isShowingMaximum" | "isShowingGrades" | "largeIncrement">
  & {
  adjudication: TeamAdjudication,
  affiliation: Team,
  onCategoryClick?: (category: InteractionCategory) => void
}

export const TeamInteractionDisplay = ({
                                         affiliation,
                                         adjudication,
                                         className,
                                         isEditable,
                                         hideValue,
                                         isShowingGrades,
                                         isShowingMaximum,
                                         largeIncrement = 5,
                                         onCategoryClick,
                                         ...props
                                       }: TeamStrategyDisplayProps) => {
  const {config} = useContext(AppContext)
  const translation = useTranslation()
  const update = useUpdateTeamAdjudication()

  return (
    <div
      {...props}
      className={tx(`grid grid-cols-${adjudication.speakers.length} gap-x-2 mobileSmall:gap-x-1 w-full`, className)}
    >
      {interactionCategories.map(category => (
        <CategoryCounter
          key={category}
          {...config.teamCategory.strategy}
          title={translation[category]}
          value={adjudication.teamCategories[category]}
          hideValue={hideValue}
          isEditable={isEditable}
          isShowingMaximum={isShowingMaximum}
          isShowingGrades={isShowingGrades}
          largeIncrement={largeIncrement}
          onChange={value => {
            update(produce(adjudication, (draft) => {
              draft.teamCategories[category] = value
            }), affiliation)
          }}
          onClick={onCategoryClick ? () => onCategoryClick(category) : undefined}
          className={tx("flex flex-1")}
        />
      ))}
    </div>
  )
}
