import "../../style.css";
import {AppContext} from "../../appContext";
import {useContext, useState} from "react";
import {Team} from "../../types/teamTypes";
import {useTranslation} from "../../localization/useTranslation";
import {tw, tx} from "@twind/core";
import {Card} from "../basic/Card";
import CategoryCounter from "../CategoryCounter";
import {encodingMapper} from "../../util/encodingMapper";
import {Coloring} from "../../configs/colors";
import {ScoreAdjudicationHeader} from "../ScoreAdjudicationHeader";
import {useMyAdjudication} from "../../util/hooks/useMyAdjudication";
import {useResult} from "../../util/hooks/useResult";
import {produce} from "immer";
import {useUpdateTeamAdjudication} from "../../api/api";
import {findTeamAdjudication} from "../../util/debateStateHelper";
import {TeamInteractionDisplay} from "../category_displays/TeamInteractionDisplay";
import {useSession} from "../../session/useSession";
import {LoadingDisplay} from "../LoadingDisplay";
import {EvaluationExpansionHeader} from "./EvaluationExpansionHeader";
import {interactionCategories} from "../../types/categories";
import {Grid} from "../basic/Grid";
import {Tag} from "../basic/Tag";

type TeamInteractionAdjudicationProps = {
  affiliation: Team,
}

/**
 * The component for an individual speaker. Creates five counters for team categories.
 */
const TeamInteractionAdjudication = ({
                                       affiliation,
                                     }: TeamInteractionAdjudicationProps) => {
  const {config, debate} = useContext(AppContext)
  const {judgeId} = useSession()
  const translation = useTranslation()
  const adjudication = useMyAdjudication()
  const result = useResult()
  const [expansionJudgeId, setExpansionJudgeId] = useState(0)
  const updateTeam = useUpdateTeamAdjudication()

  if (!debate || !adjudication || !result) {
    return <LoadingDisplay/>
  }

  const interactionResult = result[affiliation].interaction
  const hasInteractionResult = interactionResult.scores.some(value => value !== undefined)
  const average = interactionResult.average

  const teamAdjudication = findTeamAdjudication(debate, expansionJudgeId, affiliation)!


  return (
    <Card
      title={(
        <ScoreAdjudicationHeader
          title={`${translation[affiliation]} ${translation.interaction}`}
          titleColoring={{color: affiliation}}
          average={hasInteractionResult ? average : undefined}
          warning={!hasInteractionResult}
          sumColoring={encodingMapper<Coloring>(average, config.totalSpeakerScoreColorEncoding)}
        />
      )}
      expansionConfig={{isExpanded: false, enabled: true, showIcon: true}}
    >
      {({isExpanded, updateConfig}) => (
        <>
          <div
            className={tx(
              `grid grid-cols-${interactionResult.scores.length + 1} gap-x-2 mobileSmall:gap-x-1 mt-4`,
              {"mb-4": isExpanded}
            )}
          >
            {interactionResult.scores.map((score, judgeIndex) => (
              <CategoryCounter
                key={judgeIndex}
                title={judgeIndex === judgeId ? ` (${translation.you})` : translation.judge(judgeIndex)}
                colorEncoding={config.totalSpeakerScoreColorEncoding}
                value={score ?? 0}
                hideValue={score === undefined}
                isShowingWarning={score === undefined}
                onClick={() => {
                  updateConfig(prevState => produce(prevState, draft => {
                    draft.isExpanded = true
                  }))
                  setExpansionJudgeId(judgeIndex)
                }}
                className={tx("flex flex-1")}
              />
            ))}
            <CategoryCounter
              key={"spread"}
              colorEncoding={config.spreadColorEncoding}
              largeIncrement={0}
              title={translation.spread}
              value={interactionResult.spread}
              className={tx("flex flex-1")}
              onClick={() => {
                updateConfig(prevState => produce(prevState, draft => {
                  draft.isExpanded = true
                }))
                setExpansionJudgeId(-1)
              }}
            />
          </div>
          {isExpanded && (expansionJudgeId === -1 ? (
            <div className={tw("flex flex-col w-full pt-2 gap-y-2 border-t-1")}>
              <Grid
                key={"judges"}
                colCount={debate.adjudications.length + 1}
              >
                {debate.adjudications.map((adjudication, judgeIndex) => (
                  <Tag
                    coloring={{color: "white", hover: false}}
                    isRow={true}
                  >
                    <span className={tw("font-medium")}>{translation.judge(judgeIndex)}</span>
                  </Tag>
                ))}
                <Tag coloring={{color: "white", hover: false}} isRow={true}>
                  <span className={tw("font-medium")}>{translation.spread}</span>
                </Tag>
              </Grid>
              {interactionCategories.map((category) => (
                <Grid
                  key={category}
                  colCount={debate.adjudications.length + 1}
                >
                  {debate.adjudications.map((adjudication, judgeIndex) => {
                    const teamAdjudication = findTeamAdjudication(debate, judgeIndex, affiliation)
                    if (!teamAdjudication) {
                      return undefined
                    }
                    // TODO shorten names here they overflow
                    return (
                      <CategoryCounter
                        key={`${judgeIndex}-${category}`}
                        colorEncoding={config.teamCategory[category].colorEncoding}
                        title={translation[category]}
                        value={teamAdjudication.teamCategories[category]}
                        hideValue={!teamAdjudication.shouldEvaluateTeamCategories}
                      />
                    )
                  })}
                  <CategoryCounter
                    key={`${category}-spread`}
                    colorEncoding={config.spreadColorEncoding}
                    title={translation.spread}
                    value={interactionResult.subcategories[category].spread}
                  />
                </Grid>
              ))}
            </div>
          ) : (
            <div className={tw("flex flex-col items-center w-full pt-2 border-t-1")}>
              <EvaluationExpansionHeader
                expansionJudgeId={expansionJudgeId}
                isEvaluated={teamAdjudication.shouldEvaluateTeamCategories}
                onChangeIsEvaluated={() => updateTeam(produce(teamAdjudication, (draft) => {
                  draft.shouldEvaluateTeamCategories = !draft.shouldEvaluateTeamCategories
                }), affiliation, expansionJudgeId)}
              />
              <TeamInteractionDisplay
                adjudication={teamAdjudication}
                hideValue={!teamAdjudication.shouldEvaluateTeamCategories && expansionJudgeId !== judgeId}
                isEditable={expansionJudgeId === judgeId}
                isShowingMaximum={expansionJudgeId === judgeId}
                isShowingGrades={expansionJudgeId === judgeId}
                affiliation={affiliation}
                className={tw("mt-2")}
              />
            </div>
          ))}
        </>
      )}
    </Card>
  )
}
export default TeamInteractionAdjudication;
