import {defaultColorEncoding, DiscreteColorEncoding, spreadColorEncoding} from "./colorEncoding";
import {defaultGradesEncoding, GradesEncoding} from "./gradesEncoding";
import {Limits} from "../types/limits";
import {TeamCategory, SpeakerCategory} from "../types/categories";
import {PointTypes} from "../types/pointTypes";
import {SpeakerAffiliation} from "../types/teamTypes";
import {Deduction} from "../types/deduction";

/**
 * The type for defining a category
 */
export type CategoryConfig = {
  /** The limits for the category */
  limits: Limits,
  /** The default value when evaluating the category */
  defaultValue: number,
  /** The Color encoding used */
  colorEncoding: DiscreteColorEncoding,
  /** The Grades encoding used */
  gradesEncoding: GradesEncoding,
  /** When doing large increments how much should these be */
  largeIncrement: number
}

export const defaultCategoryConfigs: Record<PointTypes, CategoryConfig> = {
  points15: {
    limits: [0, 15],
    defaultValue: 5,
    colorEncoding: defaultColorEncoding.points15,
    gradesEncoding: defaultGradesEncoding.points15,
    largeIncrement: 3
  },
  points20: {
    limits: [0, 20],
    defaultValue: 6,
    colorEncoding: defaultColorEncoding.points20,
    gradesEncoding: defaultGradesEncoding.points20,
    largeIncrement: 3
  },
  points25: {
    limits: [0, 25],
    defaultValue: 9,
    colorEncoding: defaultColorEncoding.points25,
    gradesEncoding: defaultGradesEncoding.points25,
    largeIncrement: 5
  },
  points30: {
    limits: [0, 30],
    defaultValue: 10,
    colorEncoding: defaultColorEncoding.points30,
    gradesEncoding: defaultGradesEncoding.points30,
    largeIncrement: 5
  },
  points50: {
    limits: [0, 50],
    defaultValue: 17,
    colorEncoding: defaultColorEncoding.points50,
    gradesEncoding: defaultGradesEncoding.points50,
    largeIncrement: 5
  },
  points75: {
    limits: [0, 75],
    defaultValue: 25,
    colorEncoding: defaultColorEncoding.points75,
    gradesEncoding: defaultGradesEncoding.points75,
    largeIncrement: 5
  },
  points100: {
    limits: [0, 100],
    defaultValue: 35,
    colorEncoding: defaultColorEncoding.points100,
    gradesEncoding: defaultGradesEncoding.points100,
    largeIncrement: 5
  }
}

/**
 * The amount of points that should be deducted as a penalty
 */
export type DeductionConfig = Deduction[]

export const defaultDeductionConfig: DeductionConfig = [
  {name: "small", penalty: -6},
  {name: "large", penalty: -12},
]


export type SpeakerCategoryConfig = Record<SpeakerCategory, CategoryConfig>

/**
 * The configuration for the speaker points
 */
export const defaultSpeakerCategoriesConfig: SpeakerCategoryConfig = {
  linguisticPower: defaultCategoryConfigs.points20,
  appearance: defaultCategoryConfigs.points20,
  contact: defaultCategoryConfigs.points20,
  expertise: defaultCategoryConfigs.points20,
  judgement: defaultCategoryConfigs.points20,
}


export type TeamCategoryConfig = Record<TeamCategory, CategoryConfig>

/**
 * The configuration for the team categories
 */
export const defaultTeamCategoriesConfig: TeamCategoryConfig = {
  strategy: defaultCategoryConfigs.points25,
  counterSpeech: defaultCategoryConfigs.points30,
  questions: defaultCategoryConfigs.points30,
  interjections: defaultCategoryConfigs.points15,
  persuasion: defaultCategoryConfigs.points50,
}

export type TeamColorConfig = Record<SpeakerAffiliation, string>
export const teamColors: TeamColorConfig = {
  government: "gov",
  opposition: "opp",
  freeSpeeches: "free"
}

/**
 * A configuration for the OPD format
 */
export type OPDConfig = {
  speakerCategory: SpeakerCategoryConfig,
  teamCategory: TeamCategoryConfig,
  deductions: DeductionConfig,
  /** The number of free speeches in the debate */
  freeSpeechCount: number,
  /** The number of speeches per team in the debate */
  teamSpeechCount: number,
  spreadColorEncoding: DiscreteColorEncoding,
  totalSpeakerScoreColorEncoding: DiscreteColorEncoding,
  teamColors: TeamColorConfig
}

export const defaultOPDConfig: OPDConfig = {
  speakerCategory: defaultSpeakerCategoriesConfig,
  teamCategory: defaultTeamCategoriesConfig,
  deductions: defaultDeductionConfig,
  freeSpeechCount: 3,
  teamSpeechCount: 3,
  spreadColorEncoding,
  totalSpeakerScoreColorEncoding: defaultColorEncoding.points100,
  teamColors
}
