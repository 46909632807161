import {SpeakerAffiliation} from "../types/teamTypes";
import {ViewBase} from "./ViewBase";
import {tw} from "@twind/core";
import {useContext} from "react";
import SpeakerScoreAdjudication from "../components/adjudication/SpeakerScoreAdjudication";
import {useUpdateSpeakerAdjudication} from "../api/api";
import {useTranslation} from "../localization/useTranslation";
import {useMyAdjudication} from "../util/hooks/useMyAdjudication";
import {TeamStrategyAdjudication} from "../components/adjudication/TeamStrategyAdjudication";
import {BookHeart, MessageCircleQuestion, Presentation, Speech} from "lucide-react";
import {TeamInteractionAdjudication} from "../components/adjudication/TeamInteractionAdjudication";
import {TeamPersuasionAdjudication} from "../components/adjudication/TeamPersuasionAdjudication";
import {useSession} from "../session/useSession";
import {AppContext} from "../appContext";

export type TeamSpeakersViewProps = {
  affiliation: SpeakerAffiliation
}

/**
 * The TeamVies display all speakers of an affiliation and for the teams also gives the option to
 * adjudicate their scores
 *
 * @param affiliation The affiliation for the team to display
 * @constructor
 */
export const TeamViews = ({affiliation}: TeamSpeakersViewProps) => {
  const {debate} = useContext(AppContext)
  const {judgeId} = useSession()
  const translation = useTranslation()
  const updateSpeaker = useUpdateSpeakerAdjudication()
  const adjudication = useMyAdjudication()

  if (!debate) {
    return <div>{`${translation}...`}</div>
  }

  // If the debate is loaded the adjudication should be also loaded
  if (!adjudication) {
    console.error(`adjudication for ${judgeId} not found`)
    return <div/>
  }

  const speakers = adjudication[affiliation].speakers

  return (
    <ViewBase className={tw("gap-y-4")}>
      <div className={tw("flex flex-row gap-x-2 items-center")}>
        <Speech strokeWidth={2.5} size={36}/>
        <h2 id="speaker" className={tw("text-3xl font-bold")}>{translation.speaker}</h2>
      </div>
      {speakers.map(speaker => (
        <SpeakerScoreAdjudication
          key={speaker.position}
          speakerType={affiliation}
          speakerAdjudication={speaker}
          onUpdate={speakerAdjudication => {
            updateSpeaker(speakerAdjudication, affiliation)
          }}
        />
      ))}
      {affiliation !== "freeSpeeches" && (
        <>
          <div className={tw("flex flex-row gap-x-2 items-center mt-4")}>
            <Presentation strokeWidth={2.5} size={36}/>
            <h2 id="strategy" className={tw("text-3xl font-bold")}>{translation.strategy}</h2>
          </div>
          <TeamStrategyAdjudication team={affiliation}/>
          <div className={tw("flex flex-row gap-x-2 items-center mt-4")}>
            <MessageCircleQuestion strokeWidth={2.5} size={36}/>
            <h2 id="strategy" className={tw("text-3xl font-bold")}>{translation.interaction}</h2>
          </div>
          <TeamInteractionAdjudication team={affiliation}/>
          <div className={tw("flex flex-row gap-x-2 items-center mt-4")}>
            <BookHeart strokeWidth={2.5} size={36}/>
            <h2 id="strategy" className={tw("text-3xl font-bold")}>{translation.persuasion}</h2>
          </div>
          <TeamPersuasionAdjudication team={affiliation}/>
        </>
      )}
    </ViewBase>
  )
}
