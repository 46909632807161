import "../../style.css";
import {AppContext} from "../../appContext";
import {useContext, useState} from "react";
import {Team} from "../../types/teamTypes";
import {useTranslation} from "../../localization/useTranslation";
import {tw, tx} from "@twind/core";
import {Card} from "../basic/Card";
import CategoryCounter from "../CategoryCounter";
import {encodingMapper} from "../../util/encodingMapper";
import {Coloring} from "../../configs/colors";
import {ScoreAdjudicationHeader} from "../ScoreAdjudicationHeader";
import {useMyAdjudication} from "../../util/hooks/useMyAdjudication";
import {useResult} from "../../util/hooks/useResult";
import {produce} from "immer";
import {useUpdateTeamAdjudication} from "../../api/api";
import {findTeamAdjudication} from "../../util/debateStateHelper";
import {TeamStrategyDisplay} from "../category_displays/TeamStrategyDisplay";
import {useSession} from "../../session/useSession";
import {LoadingDisplay} from "../LoadingDisplay";
import {EvaluationExpansionHeader} from "./EvaluationExpansionHeader";
import {Grid} from "../basic/Grid";
import {range} from "../../util/range";
import {Tag} from "../basic/Tag";

type TeamStrategyEvaluationProps = {
  affiliation: Team,
}

/**
 * The component for an individual speaker. Creates five counters for team categories.
 */
const TeamStrategyEvaluation = ({
                                  affiliation,
                                }: TeamStrategyEvaluationProps) => {
  const {config, debate} = useContext(AppContext)
  const {judgeId} = useSession()
  const translation = useTranslation()
  const adjudication = useMyAdjudication()
  const result = useResult()
  const [expansionJudgeId, setExpansionJudgeId] = useState(0)
  const updateTeam = useUpdateTeamAdjudication()

  if (!debate || !adjudication || !result) {
    return <LoadingDisplay/>
  }

  const strategyResult = result[affiliation].strategy
  const hasStrategyResult = strategyResult.scores.some(value => value !== undefined)
  const average = strategyResult.average

  const teamAdjudication = findTeamAdjudication(debate, expansionJudgeId, affiliation)!


  return (
    <Card
      title={(
        <ScoreAdjudicationHeader
          title={`${translation[affiliation]} ${translation.strategy}`}
          titleColoring={{color: affiliation}}
          average={hasStrategyResult ? average : undefined}
          warning={!hasStrategyResult}
          sumColoring={encodingMapper<Coloring>(average, config.totalSpeakerScoreColorEncoding)}
        />
      )}
      expansionConfig={{isExpanded: false, enabled: true, showIcon: true}}
    >
      {({isExpanded, updateConfig}) => (
        <>
          <div
            className={tx(
              `grid grid-cols-${strategyResult.scores.length + 1} gap-x-2 mobileSmall:gap-x-1 mt-4`,
              {"mb-4": isExpanded}
            )}
          >
            {strategyResult.scores.map((score, judgeIndex) => (
              <CategoryCounter
                key={judgeIndex}
                title={judgeIndex === judgeId ? ` (${translation.you})` : translation.judge(judgeIndex)}
                colorEncoding={config.totalSpeakerScoreColorEncoding}
                value={score ?? 0}
                hideValue={score === undefined}
                isShowingWarning={score === undefined}
                onClick={() => {
                  updateConfig(prevState => produce(prevState, draft => {
                    draft.isExpanded = true
                  }))
                  setExpansionJudgeId(judgeIndex)
                }}
                className={tx("flex flex-1")}
              />
            ))}
            <CategoryCounter
              colorEncoding={config.spreadColorEncoding}
              largeIncrement={0} key={"spread"}
              title={translation.spread}
              value={strategyResult.spread}
              className={tx("flex flex-1")}
              onClick={() => {
                updateConfig(prevState => produce(prevState, draft => {
                  draft.isExpanded = true
                }))
                setExpansionJudgeId(-1)
              }}
            />
          </div>
          {isExpanded && (expansionJudgeId === -1 ? (
            <div className={tw("flex flex-col w-full pt-2 gap-y-2 border-t-1")}>
              <Grid
                key={"judges"}
                colCount={debate.adjudications.length + 1}
              >
                {debate.adjudications.map((adjudication, judgeIndex) => (
                  <Tag
                    coloring={{color: "white", hover: false}}
                    isRow={true}
                  >
                    <span className={tw("font-medium")}>{translation.judge(judgeIndex)}</span>
                  </Tag>
                ))}
                <Tag coloring={{color: "white", hover: false}} isRow={true}>
                  <span className={tw("font-medium")}>{translation.spread}</span>
                </Tag>
              </Grid>
              {range(0, config.teamSpeechCount).map((position) => (
                <Grid
                  key={position}
                  colCount={debate.adjudications.length + 1}
                >
                  {debate.adjudications.map((adjudication, judgeIndex) => {
                    const teamAdjudication = findTeamAdjudication(debate, judgeIndex, affiliation)
                    const speakerAdjudication = teamAdjudication?.speakers
                    if (!teamAdjudication || !speakerAdjudication) {
                      return undefined
                    }
                    return (
                      <CategoryCounter
                        key={`${judgeIndex}-${position}`}
                        colorEncoding={config.teamCategory["strategy"].colorEncoding}
                        title={translation.speech(position)}
                        value={speakerAdjudication[position].strategy}
                        hideValue={!teamAdjudication.shouldEvaluateStrategy}
                      />
                    )
                  })}
                  <CategoryCounter
                    key={`${position}-spread`}
                    colorEncoding={config.spreadColorEncoding}
                    title={translation.spread}
                    value={strategyResult.speakers[position].spread}
                  />
                </Grid>
              ))}
            </div>
          ) : (
            <div className={tw("flex flex-col items-center w-full pt-2 border-t-1")}>
              <EvaluationExpansionHeader
                expansionJudgeId={expansionJudgeId}
                isEvaluated={teamAdjudication.shouldEvaluateStrategy}
                onChangeIsEvaluated={() => updateTeam(produce(teamAdjudication, (draft) => {
                  draft.shouldEvaluateStrategy = !draft.shouldEvaluateStrategy
                }), affiliation, expansionJudgeId)}
              />
              <TeamStrategyDisplay
                adjudication={teamAdjudication}
                hideValue={!teamAdjudication.shouldEvaluateStrategy && expansionJudgeId !== judgeId}
                isEditable={expansionJudgeId === judgeId}
                isShowingMaximum={expansionJudgeId === judgeId}
                isShowingGrades={expansionJudgeId === judgeId}
                affiliation={affiliation}
                className={tw("mt-2")}
              />
            </div>
          ))}
        </>
      )}
    </Card>
  )
}
export default TeamStrategyEvaluation;
