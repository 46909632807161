import {ViewBase} from "./ViewBase";
import {TeamResultsHeader} from "../components/TeamResultsHeader";
import {SpeakerTab, SpeakerTabSorting} from "../components/SpeakerTab";
import {tw} from "@twind/core";
import {useState} from "react";
import {useTranslation} from "../localization/useTranslation";
import {Button} from "../components/basic/Button";

/**
 * The ResultsView displays the results of the debate. It shows the winning team, the points of both teams
 * and the points for each individual speaker in a tabulated sortable fashion.
 * @constructor
 */
export const ResultsView = () => {
  const translation = useTranslation()
  const [sorting, setSorting] = useState<SpeakerTabSorting>("points")

  return (
    <ViewBase className={tw("!max-w-[700px]")}>
      <TeamResultsHeader/>
      <div className={tw("flex flex-row justify-between items-center mt-6 mb-2 w-full")}>
        <span className={tw("font-bold text-xl")}>{translation.speakerTab}</span>
        <div className={tw("flex flex-row gap-x-2")}>
          <Button
            coloring={{color: sorting === "team" ? "positive" : "white"}}
            onClick={() => setSorting("team")}
            isRow={true}
          >
            {translation.team}
          </Button>
          <Button
            coloring={{color: sorting === "points" ? "positive" : "white"}}
            onClick={() => setSorting("points")}
            isRow={true}
          >
            {translation.points}
          </Button>
        </div>
      </div>
      <SpeakerTab sorting={sorting}/>
    </ViewBase>
  )
}
