import {useContext} from "react";
import {SessionContext} from "./sessionContext";

export const useSession = () => {
  const {connection, judgeId, updateSession} = useContext(SessionContext)
  const connect = async (sessionId: string, newJudgeId?: number) => {
    return connection.connectSessionWebsocket(sessionId).then(() => {
      updateSession(prevState => ({...prevState, judgeId: newJudgeId}))
    }).catch((reason => {
      console.error(reason)
      throw reason
    }))
  }

  return {
    connection,
    judgeId,
    connect,
    connectionStatus: connection.getConnectionStatus(),
    updateSession: updateSession
  }
}
