import {modalRootName} from "./components/modal/Modal";
import {ViewHandler} from "./views/ViewHandler";
import {ModalRegister} from "./components/modal/ModalRegister";
import {SessionContextProvider} from "./session/sessionContext";
import {AppContextProvider} from "./appContext";


/**
 * The start point of the React application which also handles the joining
 */
export const App = () => {
  return (
    <SessionContextProvider>
      <AppContextProvider>
        <ModalRegister>
          <div
            className={"flex flex-col w-screen h-screen overflow-hidden bg-background-200 text-white-base items-center"}
            id={modalRootName}>
            <ViewHandler/>
          </div>
        </ModalRegister>
      </AppContextProvider>
    </SessionContextProvider>
  );
}

export default App
