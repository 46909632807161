import {wsBackendUrl} from "../configs/api";
import {Operation, applyPatch, deepClone} from "fast-json-patch/commonjs/core";

/**
 * The handler for the connection to the server and management of the current session
 */
type SocketMessage<T extends object> =
  | IncrementalUpdateMessage
  | StateUpdateMessage<T>
  | EventMessage;

interface IncrementalUpdateMessage {
  type: "INCREMENTAL_UPDATE";
  payload: Operation[];
}

interface StateUpdateMessage<T extends object> {
  type: "STATE_UPDATE";
  payload: T;
}

interface EventMessage {
  type: "EVENT";
  payload: any;
}

export type UpdateHandler<T> = (newState: T) => void;
export type EventHandler<T> = (event: T) => void;

export type ConnectionStatus = "connected" | "connecting" | "disconnected" | "failed"

export class ServerConnection<TState extends object, TEvent extends object> {
  private appState: TState;
  private websocket: WebSocket | null;
  private updateHandlers: UpdateHandler<TState>[];
  private eventHandlers: EventHandler<TEvent>[];
  private sessionId: string;
  private connectionStatus: ConnectionStatus = "disconnected"

  constructor() {
    this.appState = {} as any;
    this.websocket = null;
    this.updateHandlers = [];
    this.eventHandlers = [];
    this.sessionId = "";
  }

  public getConnectionStatus = () => this.connectionStatus

  public getSessionId = () => this.sessionId

  private static applyIncrementalUpdate<TParam extends object>(
    oldState: TParam,
    update: Operation[]
  ): TParam {
    var old: TParam = deepClone(oldState);
    var newState: any;
    newState = applyPatch(old, update, false, true, true);

    var returnState: TParam;
    returnState = newState.newDocument;

    return returnState;
  }

  private handleMessage(event: MessageEvent) {
    const msg: SocketMessage<TState> = JSON.parse(event.data);
    switch (msg.type) {
      case "INCREMENTAL_UPDATE":
        this.appState = ServerConnection.applyIncrementalUpdate(
          this.appState,
          msg.payload as Operation[]
        );
        this.notifyObservers();
        break;
      case "STATE_UPDATE":
        this.appState = msg.payload;
        this.notifyObservers();
        break;
      case "EVENT":
        this.eventHandlers.forEach((handler) => handler(msg.payload));
    }
  }

  private notifyObservers() {
    this.updateHandlers.forEach((handler) => {
      handler(this.appState);
    });
  }

  public async sendPartialUpdate(update: Operation[]) {
    const msg: IncrementalUpdateMessage = {
      type: "INCREMENTAL_UPDATE",
      payload: update,
    };
    this.websocket?.send(JSON.stringify(msg));
  }

  public async sendReplacement(update: any) {
    const msg: StateUpdateMessage<TState> = {
      type: "STATE_UPDATE",
      payload: update,
    };
    this.websocket?.send(JSON.stringify(msg));
  }

  public getNumUpdateHandlers() {
    return this.updateHandlers.length;
  }

  // do not use directly, use the getState method of the correct game logic instead.
  public getState() {
    return this.appState;
  }

  public addUpdateHandler(handler: UpdateHandler<TState>) {
    this.updateHandlers.push(handler);
  }

  /**
   * Remove the update handler by reference
   */
  public removeUpdateHandler(handler: UpdateHandler<TState>) {
    this.updateHandlers = this.updateHandlers.filter(handler1 => handler !== handler1);
  }

  public publishEvent(event: any) {
    const msg: SocketMessage<TState> = {
      type: "EVENT",
      payload: event,
    };
    this.websocket?.send(JSON.stringify(msg));
  }

  public addEventHandler(handler: EventHandler<TEvent>) {
    this.eventHandlers.push(handler);
  }

  public async connectSessionWebsocket(sessionId: string) {
    if (this.sessionId === sessionId && this.connectionStatus === "connected") {
      throw Error(`already connected to ${sessionId}`)
    }
    if (this.connectionStatus === "connecting") {
      throw Error(`already trying to connect to ${sessionId}`)
    }
    this.connectionStatus = "connecting"
    this.sessionId = sessionId;
    if (this.websocket !== null) {
      this.websocket.close()
    }
    await this.openWebsocket(sessionId)
      .then(() => {
        console.info(`connected to session ${sessionId}`)
        this.connectionStatus = "connected"
      })
      .catch(() => {
        console.error(`failed to connect to session ${sessionId}`)
        this.connectionStatus = "failed"
      })
  }

  private openWebsocket(session: string) {
    return new Promise<void>((resolve, reject) => {
      const url = wsBackendUrl + "/api/v1/connect?session=" + session;
      this.websocket = new WebSocket(url);

      var isOpen = false;

      this.websocket.onopen = () => {
        this.websocket?.send('{"type":"STATE_REQUEST"}');
        isOpen = true;
        resolve();
      };
      this.websocket.onmessage = (e) => this.handleMessage(e);
      this.websocket.onclose = () => {
        if (this.sessionId === session) {
          this.openWebsocket(session);
        }
        if (!isOpen) {
          reject();
        }
      };
      this.websocket.onerror = () => {
        if (!isOpen) reject();
      };
    });
  }
}
