import React, {HTMLAttributes, ReactNode, useEffect, useState} from "react";
import {tx} from "@twind/core";
import {ChevronDown, ChevronUp} from "lucide-react";
import {produce} from "immer";
import {useOnce} from "../../util/hooks/useOnce";
import {getColoring} from "../../util/coloringHelper";

export type ExpansionConfig = {
  enabled: boolean,
  isExpanded: boolean,
  showIcon: boolean,
  isTogglingOnClick?: boolean,
}

const defaultExpansionConfig: ExpansionConfig = {
  enabled: false,
  isExpanded: true,
  isTogglingOnClick: false,
  showIcon: false
}

export type CardContentBuilderBag = ExpansionConfig & {
  toggle: () => void,
  updateConfig: React.Dispatch<React.SetStateAction<ExpansionConfig>>
}

export type CardContentBuilderFunction = (bag: CardContentBuilderBag) => ReactNode;

export type CardProps = Omit<HTMLAttributes<HTMLDivElement>, "title" | "children"> & {
  children: CardContentBuilderFunction | ReactNode,
  title?: ReactNode,
  expansionConfig?: Partial<ExpansionConfig>,
  onExpansionChange?: (isExpanded: boolean) => void
}

/**
 * A Card component which can be expanded to hide content.
 *
 * The expansion can be configured with the expansionConfig.
 *
 * When a function is provided for the children parameter you have to manually hide your content when isExpanded is false
 */
export const Card = ({title, children, expansionConfig, onExpansionChange,className}: CardProps) => {
  const [expandable, setExpandable] = useState<ExpansionConfig>({...defaultExpansionConfig, ...expansionConfig})
  const {enabled, isTogglingOnClick, showIcon, isExpanded} = expandable
  const once = useOnce(() => console.warn("isExpanded should be true when isTogglingOnClick is true"))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setExpandable(expandable), [expansionConfig])

  if (isTogglingOnClick && !isExpanded) {
    once()
  }

  const toggle = () => {
    if (!enabled) {
      return
    }
    let newState = false
    setExpandable(prevState => produce(prevState, (draft) => {
      draft.isExpanded = !draft.isExpanded
      newState = draft.isExpanded
    }))
    if(onExpansionChange){
      onExpansionChange(newState)
    }
  }

  const isUsingBuilder = typeof children === 'function'

  const renderChildren = () => {
    if (isUsingBuilder) {
      return (children as CardContentBuilderFunction)({...expandable, toggle, updateConfig: setExpandable});
    }
    if (isExpanded) {
      return children
    }
  };

  return (
    <div
      className={tx("flex flex-col py-4 px-6 mobileSmall:py-3 mobileSmall:px-3 rounded-2xl w-full bg-gray-600", {"cursor-pointer": isTogglingOnClick}, className)}
      onClick={() => isTogglingOnClick && toggle()}>
      {(!!title || showIcon) && (
        <div
          className={tx("flex flex-row justify-between items-center gap-x-2", {"mb-2": isExpanded && !isUsingBuilder})}>
          {title ?? <div/>}
          {showIcon && (isExpanded ?
              (
                <div
                  className={tx("cursor-pointer rounded-md p-1", getColoring({color: "white"}))}
                  onClick={toggle}
                >
                  <ChevronUp className={tx("-translate-y-[1px]")}/>
                </div>

              ) : (
                <div
                  className={tx("cursor-pointer rounded-md p-1", getColoring({color: "white"}))}
                  onClick={toggle}
                >
                  <ChevronDown className={tx("translate-y-[1px]")}/>
                </div>
              )
          )}
        </div>
      )}
      {renderChildren()}
    </div>
  )
}
