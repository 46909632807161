import {clamp} from "./clamp";
import {Limits} from "../types/limits";

/**
 *  Enforces that only numbers will be contained in an input
 */
export const enforceNumber = (value: string, limits?: Limits): string => {
  let result = value
  if (limits) {
    result = value.substring(0, limits[1].toString().length)
  }
  result = result.replace(/[^0-9]/g, '')
  let parsed = parseInt(result)
  if (!isNaN(parsed) && limits) {
    result = clamp(parsed, limits).toString()
  }
  return result
}
