import {ButtonHTMLAttributes} from "react";
import {Coloring} from "../../configs/colors";
import {getColoring} from "../../util/coloringHelper";
import {tx} from "@twind/core";

type ButtonSize = "small" | "normal" | "large"

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: ButtonSize
  coloring?: Coloring,
  isRow?: boolean,
}
export const Button = ({
                         children,
                         size = "normal",
                         coloring,
                         className = "",
                         disabled,
                         type = "button",
                         isRow,
                         ...restProps
                       }: ButtonProps) => {
  const styleMapping: Record<ButtonSize, string> = {
    small: "px-2 py-1 rounded-md",
    normal: "px-4 py-2 rounded-md",
    large: "px-8 py-4 text-lg rounded-lg"
  }

  const usedColoring = !!coloring ? getColoring(!disabled ? coloring : {...coloring, color: "disabled"}) : ""

  return (
    <button
      {...restProps}
      className={tx(
        styleMapping[size],
        usedColoring,
        {
          "flex flex-row gap-x-2 justify-center items-center": isRow,
          "cursor-not-allowed": disabled,
        },
        className)
      }
      type={type} disabled={disabled}
    >
      {children}
    </button>
  )
}
