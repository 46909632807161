import {Encoding} from "../types/encoding";
import {PointTypes} from "../types/pointTypes";
import {Coloring, scoreColorCodings} from "./colors";

/**
 * A discrete color encoding mapping number intervals to colors
 */
export type DiscreteColorEncoding = Encoding<Coloring>

const breakPointsToColor = (breakPoints: number[]): DiscreteColorEncoding => {
  const names = scoreColorCodings
  if (breakPoints.length !== scoreColorCodings.length) {
    console.error("breakPointsToColor: unequal length")
    return []
  }

  return breakPoints.map((value, index) => ({
    bound: value,
    value: {color: names[index], style: "background"}
  }))
}

export const defaultColorEncoding: Record<PointTypes, DiscreteColorEncoding> = {
  points15: breakPointsToColor([1, 3, 5, 8, 10, 12, 14]),
  points20: breakPointsToColor([1, 4, 7, 10, 13, 16, 19]),
  points25: breakPointsToColor([1, 5, 9, 12, 16, 20, 24]),
  points30: breakPointsToColor([1, 6, 10, 15, 19, 24, 28]),
  points50: breakPointsToColor([1, 9, 17, 24, 32, 39, 47]),
  points75: breakPointsToColor([1, 15, 25, 38, 58, 60, 70]),
  points100: breakPointsToColor([20, 30, 35, 45, 50, 65, 75]),
}

export const spreadColorEncoding: DiscreteColorEncoding = [
  {bound: 2, value: {color: "spread-good"}},
  {bound: 5, value: {color: "spread-okay"}},
  {bound: 10, value: {color: "spread-bad"}},
  {bound: 15, value: {color: "spread-very-bad"}},
]
