import {useContext} from "react";
import {calculateDebateResults} from "../results";
import {AppContext} from "../../appContext";

/**
 * Hook to easily access the debate result
 */
export const useResult = () => {
  const {debate} = useContext(AppContext)

  if (!debate) {
    return undefined
  }

  return calculateDebateResults(debate)
}
