import {useTranslation} from "../localization/useTranslation";
import {tw, tx} from "@twind/core";
import {useResult} from "../util/hooks/useResult";
import {getColoring} from "../util/coloringHelper";
import {Swords} from "lucide-react";
import {Team} from "../types/teamTypes";
import {DebateResult} from "../types/results";
import {LoadingDisplay} from "./LoadingDisplay";
import {Tag} from "./basic/Tag";

type TeamResultProps = {
  result: DebateResult
  team: Team,
  hasWon: boolean
}

/**
 * A Component for showing the results of a team.
 * @param result The result to be displayed
 * @param team The Team for which to display the result
 * @param hasWon Whether the team has won. If not it will be not highlighted
 */
const TeamResult = ({result, team, hasWon}: TeamResultProps) => {
  const translation = useTranslation()

  return (
    <div className={tx("flex flex-col gap-y-2 rounded-md p-4 mobileSmall:p-2 flex-1 font-semibold", getColoring({
      color: hasWon ? team : "disabled",
      hover: false
    }))}>
      <span className={tw("font-bold text-xl mb-1 text-center")}>{translation[team]}</span>
      <div className={tw("flex flex-row gap-x-2 justify-between items-center")}>
        <span>{`${translation.resultSum}:`}</span>
        <Tag coloring={{color: "white", hover: false}}>
          {`${result[team].teamTotal.finalScore.toFixed(2)}`}
        </Tag>
      </div>
      <div className={tw("flex flex-row gap-x-2 justify-between items-center")}>
        <span>{`${translation.speaker}:`}</span>
        <Tag coloring={{color: "white", hover: false}}>
          {`${result[team].teamSpeakerTotal.finalScore.toFixed(2)}`}
        </Tag>
      </div>
      <div className={tw("flex flex-row gap-x-2 justify-between items-center")}>
        <span>{`${translation.teamResult}:`}</span>
        <Tag coloring={{color: "white", hover: false}}>
          {`${result[team].teamCategoriesTotal.average.toFixed(2)}`}
        </Tag>
      </div>
    </div>
  )
}

/**
 * The Header for showing the victor of the debate in a comparison
 * @constructor
 */
export const TeamResultsHeader = () => {
  const result = useResult()

  if (!result) {
    return <LoadingDisplay/>
  }

  const hasGovernmentWon = result.government.teamTotal.average >= result.opposition.teamTotal.average
  const hasOppositionWon = result.opposition.teamTotal.average >= result.government.teamTotal.average

  return (
    <div className={tw("flex flex-row gap-x-6 mobileSmall:gap-x-2 items-center justify-center w-full")}>
      <TeamResult result={result} team={"government"} hasWon={hasGovernmentWon}/>
      <Swords size={24} className={tw("min-w-[24px]")}/>
      <TeamResult result={result} team={"opposition"} hasWon={hasOppositionWon}/>
    </div>
  )
}
