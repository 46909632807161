import {Globe} from "lucide-react";
import {useContext, useState} from "react";
import {ConnectionInfoButton} from "./ConnectionInfoButton";
import {useTranslation} from "../localization/useTranslation";
import {Button} from "./basic/Button";
import {tw} from "@twind/core";
import {useSession} from "../session/useSession";
import {JudgeManagementModal} from "./modal/JudgeManagementModal";
import {AppContext} from "../appContext";

/**
 * The navigation bar at the top of the screen which includes the judge selector and room code
 */
export const TopNavigation = () => {
  const context = useContext(AppContext)
  const {debate, update, language} = context
  const {judgeId} = useSession()
  const [isShowingJudgeSelection, setIsShowingJudgeSelection] = useState(false)
  const translation = useTranslation()

  const judgeName = debate?.adjudications?.find(value => value.judge.id === judgeId)?.judge.name

  return (
    <div className={"flex flex-row px-8 py-4 mobileSmall:p-3 bg-background-300 items-center w-full"}>
      <JudgeManagementModal
        id={"judgeSelectionModal"}
        isOpen={isShowingJudgeSelection}
        onCloseClick={() => setIsShowingJudgeSelection(false)}
        onBackgroundClick={() => setIsShowingJudgeSelection(false)}
      />
      <div className={"flex flex-1"}>
        {judgeId !== undefined && (
          <Button
            coloring={{color: "white"}}
            onClick={() => setIsShowingJudgeSelection(!isShowingJudgeSelection)}
          >
            <span className={tw("mobile:hidden")}>
              {!!judgeName ? `${translation.judge(judgeId)} (${judgeName})` : translation.judge(judgeId)}
            </span>
            <span className={tw("hidden mobile:block")}>
              {translation.judge(judgeId)}
            </span>
          </Button>
        )}
      </div>
      <div className={"flex flex-[2] justify-center"}>
        <ConnectionInfoButton/>
      </div>
      <div className={"flex flex-1 justify-end"}>
        <Button
          className={"flex flex-row gap-x-1 !px-0"}
          coloring={{color: "white", style: "text"}}
          onClick={() => update({...context, language: language === "de" ? "en" : "de"})}
        >
          <Globe size={24}/>
          <span className={"mobileSmall:hidden"}>{translation[language]}</span>
          <span className={"hidden mobileSmall:block"}>{translation[language].substring(0, 2)}</span>
        </Button>
      </div>
    </div>
  )
}

export default TopNavigation;
