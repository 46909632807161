import {Debate, DebateAdjudication, SpeakerAdjudication, TeamAdjudication} from "../types/states";
import {SpeakerAffiliation} from "../types/teamTypes";
import {SpeakerDeduction} from "../types/deduction";

export const findAdjudication = (debate: Debate, judgeId: number): DebateAdjudication | undefined => {
  if (!debate) {
    return
  }
  return debate.adjudications.find(adjudication => adjudication.judge.id === judgeId)
}

export const findTeamAdjudication = (debate: Debate, judgeId: number, affiliation: SpeakerAffiliation): TeamAdjudication | undefined => {
  const adjudication = findAdjudication(debate, judgeId)
  if (!adjudication) {
    return
  }
  return adjudication[affiliation]
}

export const findSpeakerAdjudication = (debate: Debate, judgeId: number, affiliation: SpeakerAffiliation, position: number): SpeakerAdjudication | undefined => {
  const teamAdjudication = findTeamAdjudication(debate, judgeId, affiliation)
  if (!teamAdjudication) {
    return
  }
  return teamAdjudication.speakers.find(value => value.position === position)
}

export const findSpeakerDeduction = (debate: Debate, affiliation: SpeakerAffiliation, position: number): SpeakerDeduction | undefined => {
  if (!debate) {
    return
  }
  return debate.deductions.find(deduction => deduction.affiliation === affiliation && deduction.position === position)
}
